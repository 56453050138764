FormFactory.presets.dental_company = (params) => {
  params = $.extend({
    model: 'dental_company',
    form: $('#dental_company_form'),
    fnItemPath: Routes.dental_company_path,
    fnItemsPath: Routes.dental_companies_path,
    redirectPath: Routes.new_dental_company_path,
    deleted_entity: t('gentitive.dental_company'),
    companyField: {
      parentForm: $('#dental_company_form'),
      target: $('#dental_company_company_id'),
    },

  }, params)

  const {form} = params
  const composition = Object.assign({},
    FormFactory.core(params),
    FormFactory.inputmask(params),
    FormFactory.redirect2sides(params),
    FormFactory.index_table_updator(params),
    FormFactory.companyField(params),
    FormFactory.delete_button(params),
  )

  if (params.mode === 'modal') {
    Object.assign(composition,
      FormFactory.modal(params),
    )
    form.find(':submit').hide()
  }

  return composition
}
