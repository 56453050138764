FormFactory.presets.analysis_laboratory = (params) => {
  params = $.extend({
    model: 'analysis_laboratory',
    form: $('#analysis_laboratory_form'),
    fnItemPath: Routes.analysis_laboratory_path,
    fnItemsPath: Routes.analysis_laboratories_path,
    redirectPath: Routes.new_analysis_laboratory_path,
    deleted_entity: t('gentitive.analysis_laboratory'),
    inputmask: {
      presets: {
        extra_charge_value_percent: Utils.inputmaskPresets.barePercentage,
        extra_charge_value_fixed: Utils.inputmaskPresets.currency,
      },
    },
  }, params)

  const {form} = params

  const extraChargeTypeField = form.find('#analysis_laboratory_entries_extra_charge_type')

  extraChargeTypeField.on('change', () => {
    const extraChargeTypeFieldValue = extraChargeTypeField.val()
    const extraChargeValue = form.find('#analysis_laboratory_entries_extra_charge_value')

    extraChargeValue.inputmask('remove')
    extraChargeValue[0].dataset.inputmask_initialized = false
    extraChargeValue.data('inputmask-preset', 'extra_charge_value_' + extraChargeTypeFieldValue)

    if (extraChargeTypeFieldValue === 'currency') {
      extraChargeValue.data('f-currency', false)
      extraChargeValue.removeClass('f-inputmask')
      extraChargeValue.addClass('f-currency')
    } else {
      extraChargeValue.removeClass('f-currency')
      extraChargeValue.addClass('f-inputmask')
    }
  })

  const composition = Object.assign({},
    FormFactory.core(params),
    FormFactory.inputmask(params),
    FormFactory.redirect2sides(params),
    FormFactory.index_table_updator(params),
    FormFactory.delete_button(params),
  )

  return composition
}
