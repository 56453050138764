FormFactory.userPassword = (params) => {
  var customPasswordFields = $('#new_password_form').find('input:text')
  customPasswordFields.first().pwstrength({
    i18n: {
      t (key) {
        // use i18next someday
        var dict = {
          'wordMinLength': t('errors.password_too_short'),
          'wordMaxLength': t('errors.your_password_long'),
          'wordInvalidChar': t('errors.your_password_contains_invalid_character'),
          'wordNotEmail': t('errors.do_not_use_e-mail_password'),
          'wordSimilarToUsername': t('errors.password_should_not_contain_login'),
          'wordTwoCharacterClasses': t('errors.use_different_classes_characters'),
          'wordRepetitions': t('errors.too_many_repetitions'),
          'wordSequences': t('errors.password_contains_sequences'),
          'errorList': t('errors.error'),
          'veryWeak': t('errors.very_week'),
          'weak': t('errors.week'),
          'normal': t('errors.normal'),
          'medium': t('errors.medium'),
          'strong': t('errors.strong'),
          'veryStrong': t('errors.very_strong'),
        }

        return dict[key]
      },
    },
  })

  // User Reset Pass

  var $passwordWindow = $('#reset_password_modal')
  var $changePassword = $('#change_password')
  var $save = $passwordWindow.find('.modal-save')
  var $form = $('#reset_password_form')
  var $password = $passwordWindow.find('#user_password')
  var $passwordConformation = $('#user_password_confirmation')

  $changePassword.on('click', function () {
    $passwordWindow.modal('show')
    $password.val($passwordConformation.val())
  })

  $save.on('click', function () {
    $form.submit()
    $changePassword.loadSpin('start')
    $(this).loadSpin('start')
  })

  $form.remote_form({
    model: 'user',
    onSuccess (user) {
      bootbox.alert('Пароль успешно изменен.<br>Новый пароль: <b>' + $password.val() + '</b>')
      // Notificator.info('Пароль пользователя изменен')
      $password.val(user._new_reset_password)
      $passwordConformation.val(user._new_reset_password)
      $changePassword.loadSpin('stop')
      $save.loadSpin('stop')
      $passwordWindow.modal('hide')
    },
    onError (data) {
      Notificator.error('Произошла ошибка')
      $changePassword.loadSpin('stop')
      $save.loadSpin('stop')
    },
  })

  // User New Custom Pass

  var $newPassWindow = $('#new_password_modal')
  var $newPassBtn = $('#new_password_btn')
  var $newPassForm = $('#new_password_form')
  var $saveNewPassBtn = $newPassWindow.find('.modal-save')
  var $customPass = $newPassWindow.find('#user_password')

  $newPassBtn.on('click', function () {
    $newPassWindow.modal('show')
  })

  $saveNewPassBtn.on('click', function () {
    $newPassForm.submit()
    $saveNewPassBtn.loadSpin('start')
  })

  $newPassForm.remote_form({
    model: 'user',
    onSuccess (user) {
      bootbox.alert('Пароль успешно изменен.<br>Новый пароль: <b>' + $customPass.val() + '</b>')
      $password.val(user._new_reset_password)
      $saveNewPassBtn.loadSpin('stop')
      $newPassWindow.modal('hide')
      $newPassForm.remote_form('clear')
    },
    onError (data) {
      // Notificator.error('Произошла ошибка')
      $saveNewPassBtn.loadSpin('stop')
    },
  })
}
