export default (params) => {
  params = $.extend({
    model: 'finance_guarantee_letter',
    form: $('#finance_guarantee_letter_form'),
    fnItemsPath: Routes.finance_guarantee_letters_path,
    fnItemPath: Routes.finance_guarantee_letter_path,
    window: $('#modal_finance_guarantee_letter_form'),
  }, params)

  const { form } = params

  const composition = Object.assign({},
    FormFactory.core(params)
  )

  if (params.mode === 'modal') {
    Object.assign(composition,
      FormFactory.modal(params)
    )
    form.find(':submit').hide()
  } else {
    Object.assign(composition,
      FormFactory.redirect(params)
    )
  }

  return composition
}
