FormFactory.presets.discount_system = function (params) {
  let $discountForm = $('#discount_system_form')
  let $discountKind = $discountForm.find('#discount_system_discount_kind')
  let $maxBonusPointsDiscount = $discountForm.find('#discount_system_max_bonus_points_discount')
  let $destroyBtn = $discountForm.find('.f-nested-destroy')

  let $discountTable = $('#discount_system_table')
  let $discountTableThead = $discountTable.find('thead')
  let $discountThValue = $discountTableThead.find('.discount_value')
  let $discountInputValue = $discountForm.find('.discount_system_discount_system_rules_discount_value input')
  let $conditionInputValue = $discountForm.find('.discount_system_discount_system_rules_condition_value input')

  applyMask()
  discountKindChangeTh()
  toggleThead()

  $discountForm.on('click', '.f-nested-destroy', function (e) {
    e.preventDefault()
    $(this).closest('tr').find('.f-rule-destroy').val('1')
    $(this).closest('tr').hide()
    toggleThead()
  })

  $discountForm.on('click', '.add_fields', function (e) {
    e.preventDefault()

    if ($('.rule-row:visible').length >= 1 && $discountKind.val() === 'bonus_points') {
      return bootbox.alert(T.discount_system.bootbox.can_add_only_one_rule_in_bonus_system)
    }

    var regexp, time
    time = new Date().getTime()
    regexp = new RegExp($(this).data('id'), 'g')
    $('.fields').append($(this).data('fields').replace(regexp, time))

    applyMask()
    toggleThead()

    if ($discountKind.val() === 'bonus_points') {
      $('.discount_system_discount_system_rules_condition_value input:visible').val(100)
      lockRuleConditionValue()
      $('.discount_system_discount_system_rules_discount_value input:visible').val('1')
    }
  })

  $discountKind.change(function () {
    discountKindChangeTh()
    destroyRules()
  })

  function applyMask () {
    $discountForm
      .find('.discount_system_discount_system_rules_discount_value input')
      .inputmask('99', { placeholder: '' })
  }

  function lockRuleConditionValue () {
    $('.discount_system_discount_system_rules_condition_value input:visible').prop('readonly', true)
  }

  function toggleThead () {
    if ($('.rule-row:visible').length > 0) {
      $discountTableThead.show()
    } else {
      $discountTableThead.hide()
    }
  }

  function discountKindChangeTh () {
    if ($discountKind.val() === 'bonus_points') {
      $discountThValue.text(T.discount_system.points)
      showMaxDiscountValue()
    }
    if ($discountKind.val() === 'accumulated_discount') {
      $discountThValue.text(T.discount_system.discount + ', %')
      $maxBonusPointsDiscount.val(0)
      hideMaxDiscountValue()
    }
  }

  function destroyRules () {
    $discountForm.find('.f-nested-destroy').trigger('click')
  }

  function hideMaxDiscountValue () {
    $('#max_bonus_points_discount_value').hide()
    $('#max_bonus_points_discount_label').hide()
  }

  function showMaxDiscountValue () {
    $('#max_bonus_points_discount_value').show()
    $('#max_bonus_points_discount_label').show()
  }

  PubSub.on('page.form.discount_system.setEdit', () => {
    lockRuleConditionValue()
  })

  params = $.extend({
    model: 'discount_system',
    form: $('#discount_system_form'),
    fnItemsPath: Routes.discount_systems_path,
    fnItemPath: Routes.discount_system_path,
    notificator_hack: true,
  }, params)

  return $.extend({},
    FormFactory.core(params),
    FormFactory.redirect(params),
  )
}
