FormFactory.presets.clinic = (params) => {
  let redirectPath = gon.application.current_clinic ? Routes.new_clinic_path : Routes.root_path

  params = $.extend({
    model: 'clinic',
    form: $('#clinic_form'),
    fnItemPath: Routes.clinic_path,
    fnItemsPath: Routes.clinics_path,
    redirectPath,
    deleted_entity: t('gentitive.clinic'),
  }, params)

  const composition = Object.assign({},
    FormFactory.core(params),
    FormFactory.inputmask(params),
    FormFactory.redirect2sides(params),
    FormFactory.index_table_updator(params),
    FormFactory.delete_button(params),
  )

  return composition
}
