import { request } from '@/lib/transport/request'
import { getMultiPrintDocumentsUrl } from '@/helpers/getMultiPrintDocumentsUrl'
import { orUndefined } from '@/_medods_standart_library/msl'

FormFactory.presets.document_type_members_editing = function (params) {
  params = $.extend({
    model: 'members_editing',
    remoteFormModel: 'category',
    form: $('#category-members-editor'),
  }, params)

  const list = params.list
  const clientLegalRepresentatives = params.clientLegalRepresentatives

  const customerLegalEntityId = orUndefined(gon.specific.payer_company?.id)
  const customerIndividualId = orUndefined(gon.specific.payer_client?.id)

  let elementsWaitingForDeletion
  const clientId = $('#client_id').val()
  let dateStart
  let dateEnd

  // TODO: crappy copypast from entry group editing
  const idOnly = function (e) { return e.id }
  const orderById = function (res, current) {
    res[current.id] = current

    return res
  }

  const moveGroup = function (data, categoryId) {
    const newData = {
      category_ids: data.categories.map(idOnly),
      document_type_ids: data.items.map(idOnly),
    }

    const originalCategories = data.categories.reduce(orderById, {})
    const originalTR = data.items.reduce(orderById, {})

    list.categoriesx('startSpin')

    $.ajax({
      method: 'POST',
      url: Routes.category_document_types_moving_path(categoryId),
      data: { category_members_moving: newData },
      success (data) {
        data.document_types.forEach(function (elem) {
          list.categoriesx('updateItem', elem, originalTR[elem.id])
        })
        data.categories.forEach(function (elem) {
          list.categoriesx('updateCategory', elem, originalCategories[elem.id])
        })
      },
      error () {
        Notificator.error(t('moving_error'))
        console.error(arguments)
      },
      complete () {
        list.categoriesx('stopSpin')
      },
    })
  }

  const deleteGroup = function (data) {
    const categoryId = list.categoriesx('getCategory').id

    const cAttributes = data.categories.map((o) => ({ id: o.id, _destroy: true }))
    const trAttributes = data.items.map((o) => ({ id: o.id, _destroy: true }))
    list.categoriesx('startSpin')

    elementsWaitingForDeletion = data

    $.ajax({
      url: Routes.category_document_types_editing_path(categoryId),
      method: 'POST',
      data: {
        category: {
          document_types_attributes: trAttributes,
          childrens_attributes: cAttributes,
        },
        category_for: location.pathname.replace('/', ''),
      },
      success () {
        elementsWaitingForDeletion.categories.forEach(function (cat) {
          list.categoriesx('removeCategory', cat)
        })
        elementsWaitingForDeletion.items.forEach(function (item) {
          list.categoriesx('removeItem', item)
        })
        Services
          .ui
          .notificator()
          .success(T.record_successfully_deleted)
      },
      error: () => {
        Notificator.error(t('error_deleting_possible') + t('category_not_empty'))
        console.error(arguments)
      },
      complete () {
        list.categoriesx('stopSpin')
        elementsWaitingForDeletion = {}
      },
    })
  }

  const exportData = function (data) {
    const newData = data.items.filter((item) => { return item.category_id !== null })
    const items = newData.map(function (item) {
      return item.id
    })

    const categories = data.categories.map(function (category) {
      return category.id
    })

    window.open('document_types/get_file_ajax?items=' +
      JSON.stringify(items) + '&categories=' + JSON.stringify(categories))
  }

  const clientIsKid = () => moment().diff(gon.specific.client.birthdate, 'years') < 18

  const haveLegalRepresentativesVar = (items) => items.some((v) =>
    Utils.includeLegalRepresentativesVar(v.data))

  const haveLegalRepresentativesOrClientVar = (items) => items.some((v) =>
    Utils.includeLegalRepresentativesOrClientVar(v.data))

  const haveDocumentDatesVar = (items) => items.some((v) =>
    Utils.includeDocumentDatesVar(v.data))

  const checkAndFillDocumentDates = (data) => {
    return new Promise((resolve) => {
      if (!haveDocumentDatesVar(data.items)) {
        resolve()

        return
      }

      Services.pubSub.subscribe(
        'MODAL_WINDOW_INPUT_DOCUMENT_DATES.DOCUMENT_DATES',
        function (documentDates) {
          Services.pubSub.reset('MODAL_WINDOW_INPUT_DOCUMENT_DATES.DOCUMENT_DATES')
          Services.pubSub.emitAsync('MODAL_WINDOW_INPUT_DOCUMENT_DATES.VISIBLE', false)

          dateStart = documentDates.dateStart
          dateEnd = documentDates.dateEnd
          resolve()
        })

      Services.pubSub.emitAsync('MODAL_WINDOW_INPUT_DOCUMENT_DATES.VISIBLE', true)
    })
  }

  const checkLegalRepresentativesAndCreateDocument = (data, inst, presettedAction) => {
    list.categoriesx('startSpin') //get selected items ids array
    const action = presettedAction || inst.settings.redirectDestination //get action for controller from init options
    const trAttributes = data.items.map((el) => el.id)
    const orderId = inst.settings.order_id
    const token = $('#operation_token').val()

    if (clientLegalRepresentatives.length > 1) {
      if (
        haveLegalRepresentativesVar(data.items) ||
        (haveLegalRepresentativesOrClientVar(data.items) && clientIsKid())
      ) {
        Services.pubSub.subscribe(
          'MODAL_WINDOW_SELECT_LEGAL_REPRESENTATIVE.LEGAL_REPRESENTATIVE_ID',
          function (legalRepresentativeId) {
            Services.pubSub.emitAsync('MODAL_WINDOW_SELECT_LEGAL_REPRESENTATIVE.VISIBLE', false)

            const params = {
              actions: action,
              ids: trAttributes,
              legal_representative_id: legalRepresentativeId,
              client_id: clientId,
              order_id: orderId,
              customer_legal_entity_id: customerLegalEntityId,
              customer_individual_id: customerIndividualId,
            }

            if (action !== 'create_and_print') {
              params.finalization_token = token
            }

            _createDocuments(
              params,
              data
            )
          })
        Services.pubSub.subscribe('MODAL_WINDOW_SELECT_LEGAL_REPRESENTATIVE.CLOSE', () => {
          list.categoriesx('stopSpin')
          Services.pubSub.reset('MODAL_WINDOW_SELECT_LEGAL_REPRESENTATIVE.CLOSE')
          Services.pubSub.reset('MODAL_WINDOW_SELECT_LEGAL_REPRESENTATIVE.LEGAL_REPRESENTATIVE_ID')
        })

        Services.pubSub.emitAsync('MODAL_WINDOW_SELECT_LEGAL_REPRESENTATIVE.VISIBLE', true)
      } else {
        const params = {
          actions: action,
          ids: trAttributes,
          client_id: clientId,
          order_id: orderId,
          customer_legal_entity_id: customerLegalEntityId,
          customer_individual_id: customerIndividualId,
        }

        if (action !== 'create_and_print') {
          params.finalization_token = token
        }

        _createDocuments(
          params,
          data
        )
      }

      return
    }

    const params = {
      actions: action,
      legal_representative_id:
          clientLegalRepresentatives.length === 1
            ? clientLegalRepresentatives[0].id
            : undefined,
      ids: trAttributes,
      client_id: clientId,
      order_id: orderId,
      customer_legal_entity_id: customerLegalEntityId,
      customer_individual_id: customerIndividualId,
    }

    if (action !== 'create_and_print') {
      params.finalization_token = token
    }

    _createDocuments(
      params,
      data
    )
  }

  const createAndPrint = function (data, inst) {
    checkAndFillDocumentDates(data).then(() => {
      checkLegalRepresentativesAndCreateDocument(data, inst, 'create_and_print')
    })
  }

  const createItems = function (data, inst) {
    checkAndFillDocumentDates(data).then(() => {
      checkLegalRepresentativesAndCreateDocument(data, inst)
    })
  }

  const _createDocuments = async function (params, data) {
    if (data.categories.length === 0) {
      params.date_start = dateStart
      params.date_end = dateEnd

      const ids = await request({
        url: Routes.multicheck_actions_doc_types_handler_documents_path(),
        method: 'POST',
        data: params,
      }).promise

      if (ids?.errors) {
        Notificator.error(t('cantCreateSomeDocuments'))
        list.categoriesx('stopSpin')

        return
      }

      if (params.actions === 'client_show' || params.actions === 'orders_show') {
        return Turbolinks.visit(location.href, { action: 'replace' })
      }

      return Turbolinks.visit(getMultiPrintDocumentsUrl(ids))
    }

    Notificator.error(t('action_not_allowed'))
    list.categoriesx('stopSpin')
  }

  return $.extend({}, {
    moveGroup,
    deleteGroup,
    exportData,
    createAndPrint,
    createItems,
  })
}
