import SmsEditor from '@/modules/variables_editor.js'
import { smsCount } from '@/modules/sms/utils.js'
import {
  APPOINTMENT_CONFIRMATION_VARIABLES_LIST,
  APPOINTMENT_VARIABLES_LIST,
  CLIENTS_BIRTHDAY_VARIABLES_LIST,
  CLIENTS_DISPATCH_VARIABLES_LIST,
  CLIENTS_FEEDBACK_VARIABLES_LIST,
  SERVICES_STATUS_VARIABLES_LIST,
} from '@/modules/sms/constants.js'
import { TEMPLATE } from '@/modules/sms/editor_presets'

export default (params) => {
  const $form = $('#messages_template_form') // Вот тут основной контейнер
  const $modalWindow = $('#messages_template_form_modal')
  const $templateTypeSelect = $('#messages_template_template_type')
  const msgPrefix = 'page.form.messages_template'
  const $editor = $form.find('.text_editor_container')
  const $deleteBtn = $modalWindow.find('.modal-delete')
  const $submit = $modalWindow.find('.modal-save')
  const $templateTypeContainer = $form.find('.template_type_container')
  const $approximateSmsCount = $form.find('.approximate_messages_quantity_value')
  const $symbolCount = $form.find('.symbol_count')
  // MUST be same keys as app/models/sms/template
  const templateTypeVariablesList = {
    appointment: APPOINTMENT_VARIABLES_LIST,
    clients_dispatch: CLIENTS_DISPATCH_VARIABLES_LIST,
    clients_birthday: CLIENTS_BIRTHDAY_VARIABLES_LIST,
    services_status: SERVICES_STATUS_VARIABLES_LIST,
    clients_feedback: CLIENTS_FEEDBACK_VARIABLES_LIST,
    appointment_confirmation: APPOINTMENT_CONFIRMATION_VARIABLES_LIST,
  }
  //
  let currentItem
  const canManageMessagesTemplate = Services.security.canManageMessagesTemplate

  params = Object.assign({
    model: 'messages_template',
    form: $form,
    window: $modalWindow,
    fnItemsPath: Routes.messages_templates_path,
    fnItemPath: Routes.messages_template_path,
    modal: { customSubmit: true },
    megamodalParams: {
      btnResize: false,
    },
    categories: { moveButton: false },
    msgPrefix,
  }, params)

  const triggerLock = (value) => {
    $templateTypeContainer.toggleClass('disabled-block', value)
  }

  const editor = new SmsEditor({
    container: $editor,
    validationCallback (value) {
      $submit.prop('disabled', !value)
    },
    onPreviewChanged (text) {
      const count = smsCount(text)
      $approximateSmsCount.text(count)
      $symbolCount.text(text.length)
    },
    onPreviewMethodStart: () => triggerLock(true),
    onPreviewMethodEnd: () => triggerLock(false),
    ...TEMPLATE,
  })

  PubSub.on('page.form.messages_template.modal.closed', () => editor.originalize())
  PubSub.on('page.form.messages_template.submitError', () => {
    editor.visualize()
    $submit.loadSpin('stop')
  })
  PubSub.on('page.form.messages_template.submitSuccess', () => {
    editor.reset()
    $submit.loadSpin('stop')
  })
  PubSub.on('page.form.messages_template.editor.reset', () => {
    editor.reset()
    document.getElementById('messages_template_title').value = ''
  })
  PubSub.on('page.form.messages_template.modal.clickSave', () => {
    editor.originalize()
    $submit.loadSpin('start')
    $form.submit()
  })
  PubSub.on('page.form.messages_template.setNew', (msg, data) => {
    currentItem = data.item
    $deleteBtn.hide()
    editor.visualize()
  })
  PubSub.on('page.form.messages_template.setEdit', (msg, data) => {
    currentItem = data.item
    $deleteBtn.show()
    editor.visualize()
  })

  $deleteBtn.click((e) => {
    if (!canManageMessagesTemplate) {
      Notificator.error(T.insufficient_access_rights)

      return
    }
    Utils.askDestroy({
      title: currentItem.title,
      url: Routes.messages_template_path(currentItem.id) + '.json',
      success () {
        params.categoryList.categoriesx('removeItem', currentItem)
        $modalWindow.megamodal('hide')
      },
    })
  })

  $submit.click((e) => {
    if (!canManageMessagesTemplate) {
      Notificator.error(T.insufficient_access_rights)
      e.stopImmediatePropagation()
    }

    if (!editor.validate()) {
      e.preventDefault()
      e.stopImmediatePropagation()
    }
  })

  $templateTypeSelect.change((e) => {
    editor.setList(templateTypeVariablesList[e.target.value])
    const valid = editor.validate()
    if (valid) {
      editor.clearPreview()
    }
  })

  return Object.assign({},
    FormFactory.core(params),
    FormFactory.modal(params),
    FormFactory.categories(params),
    FormFactory.mutable(params)
  )
}
