import shared from './shared.js'

FormFactory.orderEntryCommon = function () {
  return $.extend({}, shared, {
    getDiscountType () {
      return this.get('discount_type')
    },

    setPerformersVisibility (visible) {
      const isVisible = gon.specific.entry_good_performer_enabled

      const performerVisible = this.isComplex() ? false : (isVisible === true || visible)

      this.setFieldVisibility('assistant_id', visible)
      this.setFieldVisibility('user_id', performerVisible)
    },

    updateStoreVisibility () {
      this.setFieldVisibility('store_id', this.getKindString() === 'good')
    },

    setFieldVisibility (name, visible) {
      const field = this.field(name)
      if (!field) return

      const cell = field[0].closest('td')
      const className = 'order-entry-disabled-field'

      if (visible) {
        cell.classList.remove(className)
      } else {
        cell.classList.add(className)
      }
    },

    // custom
    updateDiscountTypeBtns (discountType) {
      const row = this.html()

      const discountTypeBtns = row.find('.discount-type-sign')
      const activeBtn = discountTypeBtns.filter(function () {
        return $(this).data('discount-type') === discountType
      })

      activeBtn.addClass('selected')

      discountTypeBtns.not(activeBtn).removeClass('selected')
    },
  })
}
