import AddressMatcher from '../components/company/address_matcher.js'

FormFactory.companyField = function (params) {
  const CompanySelect = params.companyField.target
  const FormCompanyModal = $('#form_company_modal')
  const CompanyForm = FormCompanyModal.find('#company_form')
  const $companySearch = $('#suggestion_company_search')
  let CreateCompanyBtn
  let formActive = false

  const addressFormal = CompanyForm.find('#address_formal')
  const addressFact = CompanyForm.find('#address_fact')
  const formalAddressesMatch = addressFormal.find('#addresses_match')
  const factAddressesMatch = addressFact.find('#addresses_match')

  const addressMatcher = new AddressMatcher([
    [
      addressFormal.find('#company_address_formal_index'),
      addressFact.find('#company_address_fact_index'),
    ],
    [
      addressFormal.find('#company_address_formal_country'),
      addressFact.find('#company_address_fact_country'),
    ],
    [
      addressFormal.find('#company_address_formal_region'),
      addressFact.find('#company_address_fact_region'),
    ],
    [
      addressFormal.find('#company_address_formal_area'),
      addressFact.find('#company_address_fact_area'),
    ],
    [
      addressFormal.find('#company_address_formal_city'),
      addressFact.find('#company_address_fact_city'),
    ],
    [
      addressFormal.find('#company_address_formal_street'),
      addressFact.find('#company_address_fact_street'),
    ],
    [
      addressFormal.find('#company_address_formal_house'),
      addressFact.find('#company_address_fact_house'),
    ],
    [
      addressFormal.find('#company_address_formal_flat'),
      addressFact.find('#company_address_fact_flat'),
    ],

    [
      addressFormal.find('#company_address_formal_nsi_russian_subject_id'),
      addressFact.find('#company_address_fact_nsi_russian_subject_id'),
    ],
  ])

  formalAddressesMatch.on('input', function () {
    if (this.checked) {
      addressMatcher.copyFormalAddressIntoFact()
      addressMatcher.start()
    } else {
      addressMatcher.stop()
    }

    factAddressesMatch.prop('checked', this.checked)
  })

  factAddressesMatch.on('input', function () {
    if (this.checked) {
      addressMatcher.copyFactAddressIntoFormal()
      addressMatcher.start()
    } else {
      addressMatcher.stop()
    }

    formalAddressesMatch.prop('checked', this.checked)
  })

  FormCompanyModal.find('.form-btns').hide()

  if (params.companyField.parentForm) {
    CreateCompanyBtn = params.companyField.parentForm.find('#create_company_btn')
  } else {
    CreateCompanyBtn = $('#create_company_btn')
  }

  if (!CompanyForm.length) {
    throw new Error('company form not found on page')
  }

  const select2option = CompanySelect.hasClass('company_ajax')
    ? window.DataPreset.select2ajax(Routes.search_select2_companies_path)
    : {}

  CompanySelect.select2({
    ...select2option,
    dropdownParent: CompanySelect.parents('.field-with-btn_field'),
  })

  CreateCompanyBtn.on('click', function () {
    CompanyForm.remote_form('clear')
    FormCompanyModal.modal('show')
    $companySearch.suggestionApi({ object: 'company', scope: CompanyForm })
    formActive = true
  })

  FormCompanyModal.find('.modal-save').click(function () {
    if (formActive) {
      CompanyForm.submit()
    }
  })

  FormCompanyModal.find('.modal-close').click(function () {
    formActive = false
  })

  FormCompanyModal.find('.modal-header-close').click(function () {
    formActive = false
  })

  CompanyForm.remote_form({
    model: 'company',
    onSuccess (elem) {
      formActive = false
      FormCompanyModal.modal('hide')
      const newOption = new Option(elem.title, elem.id, true, true)
      CompanySelect.append(newOption).trigger('change')
    },
  })

  return {
  }
}
