FormFactory.companies = function (params) {
  let field = params.companies.field

  if (!field) throw new Error('[companies.field] not passed')

  const populate = (msg, data) => {
    reset()
    const entry = data.item || {}
    const companyIds = entry.company_ids || []
    field.val(companyIds).change()
  }

  const reset = () => {
    field.val([]).change()
    $('.entry_type_companies .select2buttons-option').removeClass('picked')
  }

  PubSub.on('page.form.' + params.model + '.setEdit', populate)
  PubSub.on('page.form.' + params.model + '.setNew', reset)

  return {

  }
}
