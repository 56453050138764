
export const buildConfiglegalRepresentative = (modalWindow, clientForm) => {
  return {
    modalWindow,
    form: clientForm,
    config: {
      modal: {
        model: 'legal_representative',
        window: modalWindow,
        form: clientForm,
      },
      inputMask: {
        form: clientForm,
      },
      clientDiscInputMask: [
        'percentage',
        {
          rightAlign: false,
          radixFocus: false,
          autoUnmask: true,
        },
      ],
      similarItems: {
        modal: 'legal_representative',
        similarItems: {
          watch: clientForm.find(
            'input.client_surname, input.client_name, input.client_second_name, input.client_phone'
          ),
          url: Routes.filter_clients_path(),
          target: modalWindow.find('.similar-clients-list'),
          onClick (client) {
            clientForm.remote_form('update', client)
          },
          render (client) {
            const birthdate = client.birthdate
              ? Utils.dateRubyToHuman(client.birthdate)
              : ''

            return $('<tr>')
              .append(`<td class="similar-clients-list-fullname">${client.fullname}</td>`)
              .append(`<td class="similar-clients-list-phone">${client.masked_phone || client.formatted_phone || ''}</td>`)
              .append(`<td class="similar-clients-list-birthdate">${birthdate}</td>`)
          },
        },
      },
      companyField: {
        parentForm: clientForm,
        target: clientForm.find('#client_company_id'),
      },
      remoteForm: {
        model: 'client',
        onSuccess () {},
        onError () {},
      },
    },
  }
}
