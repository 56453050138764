import getCustomerCompanyModalWindowConfig from '../components/order/get_customer_company_modal_window_config.js'
import { DISCOUNT_TYPES } from '@/vue_components/order/discount/const'
import { companiesEndpoint } from '@/api_entities/companies/companies_endpoint'
import { resetDiscount } from '@/specific/orders_helpers/resetDiscount'
import { debounce } from 'lodash'
import {
  createPersonalDataEditorPositionAppRoot,
} from '@/vue_apps/ClientsModule/components/PersonalData/PersonalDataEditor/PersonalDataEditorPositionApp'

FormFactory.presets.order = function (conf) {
  const msgPrefix = 'page.form.order.'
  const $table = $('#order_entries_area')
  const $form = $('#order_form')

  const $orderSumSpan = $('#order_sum_value')
  const $orderFinalSumSpan = $('#order_sum_with_discount_value')
  const $orderDiscountSumSpan = $('#order_sum_discount_value')

  const $orderSum = $('#order_sum')
  const $orderFinalSum = $('#order_final_sum')
  const $orderDiscountSum = $('#order_discount_sum')

  const $orderDate = $('#order_date')

  /* Заглушки для масс селектов */
  const $performer = {
    val () { return gon.specific.userLast?.id || '' },
  }

  const $assistant = {
    val () { return gon.specific.assistantLast?.id || '' },
  }

  const $referral = {
    val () { return gon.specific.referralLast?.id || '' },
  }
  const $store = {
    val () { return gon.specific.storeLast?.id || '' },
  }

  const $companySubmit = $('#company_submit_btn')
  const $dmsInformer = $form.find('.dms-informer')

  const $customerCompanyModal = $('#customer_company_modal')
  const $customerCompanyForm = $customerCompanyModal.find('#company_form')
  const $customerClientModal = $('#form_client_modal')
  const $customerClientForm = $customerClientModal.find('#clients_form')
  const $clientPersonalDiscount = $('.client_discount_value')
  const $customerPointsBalance = $('#client_accounted_bonus_points')
  const $customerAccBalance = $('#client_accounted_discount')
  const defaultDiscountValue = 0.0
  const documentSelect = document.getElementById('order_document_id')

  // intial values (for edit)
  const orderStore = $store.val()
  let orderSum
  let orderFinalSum
  let orderDiscountSum
  let orderDiscountableSum
  let currentPayer

  const EDIT = 1
  const NEW = 2
  const COMPLEX = 6

  let ORDER_FORM_MODE
  let ORDER_INITIALIZED = false

  PubSub.on(msgPrefix + 'setNew', () => { ORDER_FORM_MODE = NEW })
  PubSub.on(msgPrefix + 'setEdit', () => { ORDER_FORM_MODE = EDIT })

  const referralForm = FormFactory.build('referral', {
    mode: 'modal',
  })

  const medicalPolicyForm = FormFactory.build('medical_policy', {
    mode: 'modal',
    companyField: {
      parentForm: $('#medical_policy_form'),
      target: $('#medical_policy_insurance_company_id'),
    },
  })

  medicalPolicyForm.subscribe('submitSuccess', (msg, data) => {
    gon.specific.client_medical_policies.push(data.item)
  })

  const customerCompanyModalWindowConfig = getCustomerCompanyModalWindowConfig($customerCompanyModal, $customerCompanyForm)
  const companyFormModalWindow = FormFactory.build('companyFormModalWindow', customerCompanyModalWindowConfig)

  Services.pubSub.subscribe('ORDER.OPEN_MODAL_CREATE_CUSTOMER_LEGAL_ENTITY', () => {
    companyFormModalWindow.manageWindow('show')
  })

  $customerCompanyForm.on('ajax:success', (_event, createdCompany) => {
    Services.pubSub.emitAsync('ORDER.PAYER_SELECTOR.SET_PAYER', createdCompany)
  })

  $customerClientForm.on('ajax:success', (_event, client) => {
    Services.pubSub.emitAsync('ORDER.PAYER_SELECTOR.UPDATE_CLIENT_INFO', client)
  })

  $companySubmit.hide()

  const typeSelectColumns = [
    {
      render (item, td) {
        for (let i = 0; i < item.stocks.length; i++) {
          if (item.stocks[i].store_id === orderStore) {
            return td.append(item.stocks[i].amount).attr('class', 'tooltip-bottom')
              .attr('title', t('remains_stock')).tooltip({ container: 'body' })
          }
        }

        return td
      },
    },
  ]

  const params = $.extend({
    model: 'order',
    form: $form,
    fnItemsPath: Routes.orders_path,
    fnItemPath: Routes.order_path,
    orderEntryList: {
      container: $table,
    },
    entryTypeSelector: [
      {
        selector: '.f-order-main-selector .entry_type_selector',
        editable: true,
        columns: typeSelectColumns,
        includePrice: true,
        forCurrentClinic: true,
      },
      {
        selector: '.f-dynamic-members-selector .entry_type_selector',
        editable: false,
        columns: typeSelectColumns,
        filterItems (item) {
          return parseInt(item.kind) !== COMPLEX
        },
        includePrice: true,
        forCurrentClinic: true,
      },
      {
        selector: '.f-dynamic-consumables-selector.enabled .entry_type_selector',
        editable: false,
        columns: typeSelectColumns,
        onlyGoods: true,
        includePrice: true,
        forCurrentClinic: true,
      },
    ],
    nestedFields: {
      model: 'entries',
    },
    referralField: {
      form: referralForm,
      button: $('#create_referal_btn'),
      select: $referral,
    },

    medicalPolicyField: {
      form: medicalPolicyForm,
      button: $('#create_medical_policy_btn'),
      select: $('#order_medical_policy_id'),
      clientId: gon.specific.client.id,
      model: 'order',
    },
    companyField: {
      target: $('#order_customer_company_id'),
      parentForm: $form,
    },
    inputmask: {
      presets: {
        discount_percent: Utils.inputmaskPresets.barePercentage,
        discount_currency: Utils.inputmaskPresets.currency,
      },
    },
    maskParams: {
      prefix: '',
      groupSeparator: ' ',
      rightAlign: false,
      jitMasking: true,
      autoUnmask: true,
      allowMinus: false,
    },
  }, conf)

  function filterMassChangedAttrs (attrs, changed) {
    const map = {
      store: 'store_id',
      date: 'date',
      referral: 'referral_id',
      performer: 'user_id',
    }

    Object.keys(map).forEach((key) => {
      if (key !== changed) {
        delete attrs[map[key]]
      }
    })
  }

  const emitAttributesChanged = function (opts) {
    const attributes = {
      user_id: $performer.val(),
      assistant_id: $assistant.val(),
      store_id: $store.val(),
      date: $orderDate.val(),
      clinic_id: $('#order_clinic_id').val(),
      client_id: $('#order_client_id').val(),
      referral_id: $referral.val(),
    }
    if (opts) {
      if (opts.change) {
        filterMassChangedAttrs(attributes, opts.change)
      }

      opts.attributes = attributes
    } else {
      opts = { attributes }
    }

    PubSub.emit(
      msgPrefix + 'orderEntryList.askSetAttributes', opts
    )
  }

  /// ***********************EVENTS******************************

  $orderDate.on('change', function () {
    emitAttributesChanged({ change: 'date' })
  })

  $('#add_discount').on('click', function () {
    Utils.OrderDiscountVue.setData({
      discountValue: 0,
      discountType: DISCOUNT_TYPES.PERCENT,
      discountReasonId: 0,
      orderSum,
      orderDiscountableSum,
      isMassDiscount: true,
      payer: currentPayer,
    })

    PubSub.emit(
      msgPrefix + 'calculateDiscount.askShow',
      {
        sum: orderDiscountableSum,
        entries_amount: $table.find('.f-order-entry').not('.f-order-entry-complex').not('.f-order-nested-consumable').filter(function () {
          return $(this).find('.order_entries_discount_disabled').find('input').val() !== 'true'
        }).length,
      }
    )
  })

  $('#add_bonus_discount').on('click', function () {
    if (parseInt($customerPointsBalance.text()) === 0) return

    PubSub.emit(
      msgPrefix + 'calculateBonusDiscount.askShow',
      {
        sum: orderDiscountableSum,
        entries_amount: $table.find('.f-order-entry').not('.f-order-entry-complex').not('.f-order-nested-consumable').filter(function () {
          return $(this).find('.order_entries_discount_disabled').find('input').val() !== 'true'
        }).length,
      }
    )
  })

  $table.on('click', '.referral-icon', function () {
    $(this)
      .closest('.entry-referral')
      .find('.f-entry-referral')
      .select2('open')
  })

  $('.mass-select').on('change', function () {
    const elementName = $(this).find('select').data('name')
    emitAttributesChanged({ change: elementName })
    $('.entry-' + elementName).find('.f-entry-' + elementName).trigger('change')
  })

  const massSelectors = [
    '#mass-select-store',
  ]

  massSelectors.forEach(function (elem) {
    $(elem).select2({
      // theme: 'bootstrap',
      width: '140px',
    })
  })

  $form.find('.order_customer_ajax').select2(DataPreset.select2ajax(Routes.search_select2_clients_path))

  /* Вызов скидки по нажатию на столбец скидка энтри */
  $('.panel-body').on('click', '.discount-container.js_listener', function (e) {
    e.preventDefault()

    /* где-то тригерятся discount-type-sign, поэтому их игнорируем */
    if (!e.clientX) {
      return
    }

    const $discountItem = $(this).find('.f-order-entry-discount-value')
    /* не открывать модалку если атрибут для чтения */
    if ($discountItem.attr('readonly')) return

    let $entryRow = $(this).parents('.entry_row')
    if (!$entryRow.length) {
      $entryRow = $(this).parents('.entry_member_row')
    }

    // const $discountReasonId = $(this).find('.f-order-entry-discount-reason-id')
    const $discountType = $(this).find('.discount-type-select').children('.selected')

    const discountValue = parseFloat($discountItem.val())
    const discountType = $discountType.data('discount-type')
    const entryTitle = $entryRow.find('.entry-title-field').val()
    const entryId = +$entryRow.find('input.f-order-entry-id').val() || 0
    const entrySum = +$entryRow.find('input.f-order-entry-sum').val() || 0

    Utils.OrderDiscountVue.setData({
      discountValue,
      discountType,
      discountReasonId: null,
      orderSum,
      entryTitle,
      entryId,
      entrySum,
      entryElement: $entryRow.get(0),
      orderDiscountableSum: entrySum,
    })

    PubSub.emit(
      msgPrefix + 'calculateDiscount.askShow',
      {
        sum: orderDiscountableSum,
        entries_amount: $table.find('.f-order-entry').not('.f-order-entry-complex').not('.f-order-nested-consumable').filter(function () {
          return $(this).find('.order_entries_discount_disabled').find('input').val() !== 'true'
        }).length,
      }
    )
  })

  //= ====== end of hacks

  //* ***********************END OF EVENTS******************************
  //
  const clientForm = FormFactory.build('client', { mode: 'order' })

  $('#order_fast_edit_client_info').on('click', function (e) {
    e.preventDefault()
    clientForm.editItem()
    PubSub.emit('page.form.client.emkLastNumber.get')
    createPersonalDataEditorPositionAppRoot('#form_client_modal')
  })

  //
  // pubsub subscriptions
  //

  PubSub.on(msgPrefix + 'entryTypeSelector.selected', function (msg, data) {
    const proto = data.item
    const selector = data.selector

    PubSub.publish(msgPrefix + 'orderEntryList.askAddEntry', {
      item: proto,
      selector,
    })
  })

  PubSub.on(msgPrefix + 'orderEntryList.listChanged', function (msg, data) {
    orderSum = data.sum
    orderFinalSum = data.final_sum
    orderDiscountSum = data.discount_sum
    orderDiscountableSum = data.discountable_sum

    $orderSum.val(orderSum)
    $orderFinalSum.val(orderFinalSum)
    $orderDiscountSum.val(orderDiscountSum)

    $orderSumSpan.val(orderSum)
    $orderFinalSumSpan.val(orderFinalSum)
    $orderDiscountSumSpan.val(orderDiscountSum)

    PubSub.emit(
      msgPrefix + 'calculateBonusDiscount.recalculate',
      { sum: orderDiscountableSum }
    )
  })
  // trigger for hiding unused discount type

  PubSub.on(msgPrefix + 'calculateDiscount.newValue', function (msg, data) {
    // use sync here so it doesn't run concurrently with price rounding, that uses promises
    PubSub.publishSync(msgPrefix + 'orderEntryList.askForceAttributes', {
      attributes: {
        discount_value: data.discount_value,
        discount_type: data.discount_type,
        difference: data.difference,
        entryElement: data.entryElement,
      },
    })

    // apply rounding only if the setting is has changed
    if (data.shouldRoundPrices !== undefined) {
      const complexFieldSets = []

      $table.find('.f-order-entry:visible')
        .toArray()
        .forEach((row) => {
          const fieldSet = $(row).nestedFieldSet()

          if (fieldSet.isConsumable()) { return row }

          if (fieldSet.isComplex()) {
            complexFieldSets.push(fieldSet)

            return row
          }

          const originalPrice = fieldSet.html().find('td.hidden .f-order-entry-price').val()

          if (data.shouldRoundPrices) { return fieldSet.roundPrice() }

          fieldSet.set('price', originalPrice)
          fieldSet.recalculate()
        })

      complexFieldSets.forEach((complex) => {
        complex.recalculate()
      })

      PubSub.emit(msgPrefix + 'orderEntryList.recalculateTotals')
    }
  })

  PubSub.on(msgPrefix + 'calculateBonusDiscount.newValue', function (msg, data) {
    PubSub.emit(msgPrefix + 'orderEntryList.askForceAttributes', {
      attributes: {
        discount_value: data.discount_value,
        discount_type: data.discount_type,
        difference: data.difference,
      },
    })
  })

  const updatePayerDiscount = (payer) => {
    const clientBonusPoints = gon.specific.accounted_bonus_points
    let accountedDiscount = gon.specific.accounted_discount
    const personalDiscount = parseFloat(payer.discount || defaultDiscountValue)
    $clientPersonalDiscount.text(personalDiscount)

    // Скидка должна выставляться в режиме редактирования только при смене плательщика.
    // При его изначальной установке скидку проставлять не нужно.
    // Сначала устанавливаем скидку, потом выпускаем `orderEntryList.askSetAttributes`,
    // чтобы проставить скидку для будущих энтрей (при их добавлении) и всё пересчитать.
    if (ORDER_FORM_MODE === NEW || (ORDER_FORM_MODE === EDIT && ORDER_INITIALIZED)) {
      PubSub.emit(msgPrefix + 'orderEntryList.askSetNewPayerDiscount', personalDiscount)
    }

    if (!ORDER_INITIALIZED) {
      ORDER_INITIALIZED = true
    }

    if (accountedDiscount !== undefined) {
      // во избежание бОльших костылей
      if (
        payer.id === gon.specific.client.id &&
        payer.acc_discount === undefined
      ) {
        payer.acc_discount = accountedDiscount
      }

      accountedDiscount = payer.accumulated_discount || payer.acc_discount || defaultDiscountValue
      $customerAccBalance.text(accountedDiscount)
    }

    PubSub.emit(msgPrefix + 'calculateDiscount.payerDiscountChange', { accountedDiscount, personalDiscount })

    PubSub.emit(msgPrefix + 'orderEntryList.askSetAttributes', {
      attributes: {
        discount_value: personalDiscount,
        discount_type: 'percent',
      },
    })

    if (clientBonusPoints !== undefined) {
      // во избежание бОльших костылей
      if (
        payer.id === gon.specific.client.id &&
        payer.bonus_points === undefined
      ) {
        payer.bonus_points = clientBonusPoints
      }

      const bonusPoints = payer.bonus_points || 0
      $customerPointsBalance.text(bonusPoints)

      PubSub.emit(msgPrefix + 'calculateBonusDiscount.setNewPayerInfo', {
        bonusPoints,
        personalDiscount,
      })
    }
  }
  const updatePayerDocumentsList = (payer) => {
    if (!documentSelect) {
      throw new Error('updatePayerDocumentsList: Order document select is missing on page')
    }

    const existingDocumentsIds = new Set()
    const options = Array.from(documentSelect.options)

    options.forEach((option) => {
      if (option.dataset.added_by_company) {
        documentSelect.removeChild(option)
      }
      existingDocumentsIds.add(+option.value)
    })

    if (!payer.is_company) return

    $(documentSelect).loadSpin('start')
    debouncedLoadCompanyDocuments(payer, existingDocumentsIds)
  }

  const debouncedLoadCompanyDocuments = debounce(loadCompanyDocuments, 1000)

  function loadCompanyDocuments (companyId, existingDocumentsIds) {
    companiesEndpoint.getDocuments(companyId)
      .then(({ documents }) => {
        const options = new Set(Array.from(documentSelect.options).map((item) => item.value))

        documents.forEach((doc) => {
          if (options.has(`${doc.id}`)) { return }

          const newOption = document.createElement('option')

          newOption.value = doc.id
          newOption.textContent = [
            doc.title,
            ` №${doc.number}`,
            t('from'),
            moment(doc.created_at).format(gon.localization.date_format.toUpperCase()),
          ].join('')
          newOption.dataset.added_by_company = 'true'

          documentSelect.appendChild(newOption)
        })

        $(documentSelect).val(gon.specific.order.document_id)
      })
      .catch(Utils.reportError('order:loadCompanyDocuments'))
      .finally(() => $(documentSelect).loadSpin('stop'))
  }

  const updateGonOrderSpecific = (payer) => {
    currentPayer = payer
    if (payer.is_company) {
      gon.specific.payer_company = payer
      gon.specific.order.customer_company_id = payer.id
      gon.specific.payer_client = null
      gon.specific.order.customer_client_id = null
    } else {
      gon.specific.payer_client = payer
      gon.specific.order.customer_client_id = payer.id
      gon.specific.payer_company = null
      gon.specific.order.customer_company_id = null
    }
  }

  // БАЖЕСТВЕННЫЕ КОСТЫЛИ
  // ORDER.PAYER_SELECTOR.PAYER_CHANGED
  // происходит пересчет несколько раз, поэтому требуется Number
  let canResetDiscountAfterPayerChange = 0
  const startPayerIsCompany = gon.specific.order?.customer_company_id
  const MIN_PAYER_CHANGED_CALLS_BEFORE_RESET = startPayerIsCompany
    ? 3
    : 2

  /**
   * Событие выпускается в left_order_info.vue, причём сразу при загрузке страницы,
   * а не только при выборе заказчика пользователем.
   */
  Services.pubSub.subscribe('ORDER.PAYER_SELECTOR.PAYER_CHANGED', (payer) => {
    canResetDiscountAfterPayerChange += 1

    try {
      updatePayerDiscount(payer)
      updatePayerDocumentsList(payer)
      updateGonOrderSpecific(payer)

      if (canResetDiscountAfterPayerChange > MIN_PAYER_CHANGED_CALLS_BEFORE_RESET) { resetDiscount() }
    } catch (error) {
      Utils.reportError('order:payer change')(error)
    }
  })

  PubSub.on(msgPrefix + 'medicalPolicyField.change', function (msg, data) {
    Services.pubSub.emitAsync('ORDER.MEDICAL_POLICE.POLICE_CHANGED', data.value)
    const selectedClientMedicalPolicyId = Number(data.value)
    const selectedClientMedicalPolicy = gon.specific.client_medical_policies.find((cmp) => cmp.id === selectedClientMedicalPolicyId)

    if (!selectedClientMedicalPolicy) {
      $dmsInformer.text(T.no)
      $dmsInformer.removeClass('by_dms_false by_dms_true')
      $dmsInformer.addClass('by_dms_false')
    } else if (selectedClientMedicalPolicy.policyType === 'dms') {
      $dmsInformer.text(T.yes)
      $dmsInformer.removeClass('by_dms_false by_dms_true')
      $dmsInformer.addClass('by_dms_true')
    } else {
      $dmsInformer.text(T.no)
      $dmsInformer.removeClass('by_dms_false by_dms_true')
      $dmsInformer.addClass('by_dms_false')
    }

    if (!data.value) {
      Services.pubSub.emitAsync('ORDERS.INSURANCE_COMPANY_SELECTED_MEDICAL_POLICY', null)

      return
    }

    const $companySelect = $form.find('#order_customer_company_id')
    $companySelect.loadSpin('start')

    $.ajax({
      url: Routes.get_by_medical_policy_companies_path(),
      method: 'POST',
      data: { medical_policy_id: data.value },
      success (res) {
        Services.pubSub.emitAsync('ORDERS.INSURANCE_COMPANY_SELECTED_MEDICAL_POLICY', res)

        const $option = $('<option selected>' + res.company_short_info + '</option>').val(res.company_id)
        $companySelect.empty().append($option).val(res.company_id).trigger('change')
      },
      error (err) {
        Notificator.error('can not find company')
        console.error(err)
      },
      complete () {
        $companySelect.loadSpin('stop')
      },
    })
  })

  const operationToken = $('#operation_token').val()

  DocumentForm.init({
    documents: gon.specific.documents,
    client_id: gon.specific.client_id,
    order_id: gon.specific.order_id,
    clientLegalRepresentatives: gon.specific.client.legal_representatives,
    clientLegalRepresentativesModalSelector: '#order_preset_client_legal_representatives_modal',
    documentDatesModalSelector: '#order_preset_document_dates_modal',
    finishedLegalRepresentativeId: $('#finished_legal_representative_id'),
    customer_legal_entity_id: gon.specific.payer_company ? gon.specific.payer_company.id : undefined,
    customer_individual_id: gon.specific.payer_client ? gon.specific.payer_client.id : undefined,
    itemEditable: false,
    groupEditable: false, //enable document type edit in orders
    groupEditOlnly: false,
    source: 'edit',
    actions: {
      createItems: T.create,
    },
    redirectDestination: 'orders_edit', //method in controller for redirect after action
    unfinished: true,
    finalization_token: operationToken || null,
    complete () {
      $('#documents-modal').modal('hide')
      const categoryList = $('#documents-category')
      categoryList.categoriesx('stopSpin')
      Notificator.info(t('new_document_created'))
    },
  })

  return $.extend({},
    FormFactory.core(params),
    FormFactory.entryTypeSelector(params),
    FormFactory.redirect(params),
    FormFactory.currency(params),
    FormFactory.nestedFields(params),
    FormFactory.inputmask(params),
    FormFactory.referralField(params),
    FormFactory.medicalPolicyField(params),
    FormFactory.companyField(params),

    FormFactory.orderEntry(params),
    FormFactory.orderEntryMember(params),
    FormFactory.orderEntryConsumable(params),
    FormFactory.orderEntryList(params),

    FormFactory.calculateDiscount(params),
    FormFactory.calculateBonusDiscount(params)
  )
}
