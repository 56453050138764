<template>
  <modal
    in-body
    modal-class="relations-modal"
    :modal-visibility="visibility"
    modal-size="md"
    @close="close"
  >
    <template #header>
      <span class="fad fa-chart-network" />
      {{ t('legal_rep_relations') }}
    </template>
    <template #body>
      <div
        v-loading="loading || submitting"
        class="relations-list__container"
      >
        <div v-if="!loading && !legalReps.length">
          {{ t('no_legal_representatives') }}
        </div>
        <div v-else>
          <span class="prompt-notice">{{ t('egisz.components.legal_rep_relations_modal.subtitle_prompt') }}</span>
          <ul class="relations-list">
            <li
              v-for="lr in legalReps"
              :key="lr.id"
              class="relations-list__item"
            >
              <span>{{ lr.name }}</span>
              <el-select
                :value="loading ? null : relationsMap[lr.id].relation_type_id"
                @change="setRelation(lr.id, $event)"
              >
                <el-option
                  v-for="def in relationDefinitions"
                  :key="def.id"
                  :value="def.id"
                  :label="def.title"
                />
              </el-select>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <template #footer-left>
      <button
        type="button"
        class="btn btn-sm btn-link"
        @click="loadRelativeTypes"
      >
        <span>{{ t('egisz.components.legal_rep_relations_modal.refresh_catalog') }}</span>
      </button>
    </template>

    <template #footer-right>
      <button
        class="btn btn-sm btn-success btn-with-icon"
        type="button"
        :disabled="!hasRelations"
        @click="sendRelations"
      >
        <i class="fad fa-save btn-with-icon_icon" />
        <span>{{ t('save') }}</span>
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from '@/vue_components/modal'
import formatter from '@/lib/formatters/formatter'
import { clientsEndpoint } from '@/api_entities/clients/clients_endpoint'

export default {
  name: 'LegalRepRelationsModalForClientForm',
  components: { Modal },
  props: {
    clientId: {
      type: Number,
      required: true,
    },
    /**
     * @type {import('vue').PropOptions<Array<{id: string, name: string}>>}
     */
    legalReps: {
      type: Array,
      required: true,
    },

    visibility: Boolean,

    relations: {
      type: Array,
      default: null,
    },

    relationsLoading: Boolean,

    searchClientLegalRepresentative: {
      type: Object,
      required: true,
    },
  },

  data () {
    return {
      relationDefinitions: [],
      relationsMap: {},
      loading: false,
      submitting: false,
      animateTo: 0,
    }
  },

  computed: {
    hasRelations () {
      return Object.keys(this.relationsMap).length > 0
    },
  },

  watch: {
    relations () {
      this.relationsMap = this.getRelationsMap()
    },
  },

  created () {
    if (!this.legalReps.length) return

    this.relationsMap = this.getRelationsMap()

    this.loadRelativeTypes()
  },

  methods: {
    loadRelativeTypes () {
      this.loading = true

      return clientsEndpoint.getRelativeTypes()
        .then((defs) => {
          this.relationDefinitions = defs
        })
        .catch(Utils.reportError(
          'legalReps:loadRelativeTypes()',
          t('egisz.messages.errors.request.netrika_load_catalog_error')
        ))
        .finally(() => { this.loading = false })
    },

    sendRelations () {
      this.submitting = true

      return clientsEndpoint.setRelations(Object.values(this.relationsMap), this.searchClientLegalRepresentative)
        .then(() => {
          this.close()
          Notificator.success(t('changes_updated'), '', 3000)
        })
        .catch(Utils.reportError('clients:sendRelations'))
        .finally(() => {
          this.submitting = false
        })
    },

    legalRepName (legalRep) {
      return formatter.formatValue({
        name: legalRep.name,
        surname: legalRep.surname,
        secondName: legalRep.second_name,
      }, ['client:fullName'])
    },

    close () {
      this.$emit('update:visibility', false)
    },

    getRelationsMap () {
      return this.legalReps.reduce((map, lr) => {
        let relation = this.relations.find((relation) => parseInt(relation.legal_representative_id) === parseInt(lr.id))
        if (!relation) {
          relation = {
            legal_representative_id: parseInt(lr.id),
            relation_type_title: '',
            relation_type_id: null,
          }
        }
        map[lr.id] = relation

        return map
      }, {})
    },

    setRelation (legalRepresentativeId, relationTypeId) {
      this.relationsMap[legalRepresentativeId].relation_type_id = relationTypeId
      this.relationsMap[legalRepresentativeId].relation_type_title = this.getRelationDefinitionTitle(relationTypeId)
    },

    getRelationDefinitionTitle (relationTypeId) {
      const relation = this.relationDefinitions.find((item) => item.id === relationTypeId)

      return relation
        ? relation.title
        : ''
    },
  },
}
</script>
