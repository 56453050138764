FormFactory.presets.template_record_members_editing = function (params) {
  params = $.extend({
    model: 'members_editing',
    remoteFormModel: 'category',
    form: $('#category-members-editor'),
  }, params)

  const list = params.list
  let elementsWaitingForDeletion

  // TODO: crappy copypast from entry group editing
  var idOnly = function (e) { return e.id }
  var orderById = function (res, current) {
    res[current.id] = current

    return res
  }

  const moveGroup = function (data, categoryId) {
    var newData = {
      category_ids: data.categories.map(idOnly),
      template_record_ids: data.items.map(idOnly),
    }

    var originalCategories = data.categories.reduce(orderById, {})
    var originalTR = data.items.reduce(orderById, {})

    list.categoriesx('startSpin')

    $.ajax({
      method: 'POST',
      url: Routes.category_template_records_moving_path(categoryId),
      data: {category_members_moving: newData},
      success (data) {
        data.template_records.forEach(function (elem) {
          list.categoriesx('updateItem', elem, originalTR[elem.id])
        })
        data.categories.forEach(function (elem) {
          list.categoriesx('updateCategory', elem, originalCategories[elem.id])
        })
      },
      error () {
        Notificator.error(t('moving_error'))
        console.error(arguments)
      },
      complete () {
        list.categoriesx('stopSpin')
      },
    })
  }

  const deleteGroup = function (data) {
    var categoryId = list.categoriesx('getCategory').id

    const cAttributes = data.categories.map((o) => ({id: o.id, _destroy: true}))
    const trAttributes = data.items.map((o) => ({id: o.id, _destroy: true}))
    list.categoriesx('startSpin')

    elementsWaitingForDeletion = data

    $.ajax({
      url: Routes.category_template_records_editing_path(categoryId),
      method: 'POST',
      data: {
        category: {
          template_records_attributes: trAttributes,
          childrens_attributes: cAttributes,
        },
      },
      success () {
        elementsWaitingForDeletion.categories.forEach(function (cat) {
          list.categoriesx('removeCategory', cat)
        })
        elementsWaitingForDeletion.items.forEach(function (item) {
          list.categoriesx('removeItem', item)
        })
        Services
          .ui
          .notificator()
          .success(T.record_successfully_deleted)
      },
      error: () => {
        Notificator.error(t('error_deleting_possible') + t('category_not_empty'))
        console.error(arguments)
      },
      complete () {
        list.categoriesx('stopSpin')
        elementsWaitingForDeletion = {}
      },
    })
  }

  const exportData = function (data) {
    var items = data.items.map(function (item) {
      return item.id
    })

    var categories = data.categories.map(function (category) {
      return category.id
    })

    window.open('template_records/get_file_ajax?items=' +
      JSON.stringify(items) + '&categories=' + JSON.stringify(categories))
  }

  return $.extend({}, {
    moveGroup,
    deleteGroup,
    exportData,
  })
}
