FormFactory.dentalOrderEntryList = function (params) {
  var ENTRY_NOT_READY = 1

  var msgPrefix = 'page.form.' + params.model + '.dentalOrderEntryList.'

  var container = params.dentalOrderEntryList.container
  var attributes = {}
  var entriesTemplate = $('._template_dental_order_entries')
  var round = Utils.moneyRound

  //
  // core funcs
  //

  var togglePriceFields = function () {
    if (gon.application.settings.dental_order_price) {
      $('.dental-order-price').hide()
    } else {
      $('.dental-order-price').show()
    }
  }

  var init = function () {
    container.find('.entry_row').each(function (i, e) {
      var row = $(e).nestedFieldSet()
      row.init()
      row.hideContent()
    })
    togglePriceFields()
    triggerListChanged()
  }

  var updateIndexesTimeout
  var updateIndexes = function () {
    if (updateIndexesTimeout) clearTimeout(updateIndexesTimeout)

    // massive complex addition calls updateIndex too many times and freezes ui
    updateIndexesTimeout = setTimeout(function () {
      container[0].querySelectorAll('.entry_row').forEach(function (e, i) {
        var row = $(e).nestedFieldSet()
        row.setIndex(i)
        row.init()
      })
    }, 10)
  }

  var buildItem = function (proto) {
    return {
      entry_type_id: proto.id,
      amount: proto.amount,
      price: proto.price,
      title: proto.title,
    }
  }

  let triggerListChangedDelay = null
  const triggerListChanged = function () {
    if (triggerListChangedDelay) clearTimeout(triggerListChangedDelay)
    triggerListChangedDelay = setTimeout(function () {
      const result = { sum: 0 }

      container.find('.entry_row').each(function (i, e) {
        const row = $(e).nestedFieldSet()

        if (row.isDeleted()) return

        result.sum += parseFloat(row.get('sum'))
      })

      result.sum = round(result.sum)

      PubSub.emit('page.form.' + params.model + '.updateComponents')
      PubSub.emit(msgPrefix + 'listChanged', result)
    }, 10)
  }

  // remove passed attributes & undefineds
  var cleanAttributes = function (attrs) {
    var omitList = Array.prototype.slice.call(arguments, 1)
    attrs = _.omit.apply(_, [attrs].concat(omitList))

    return _.omit(attrs, _.isUndefined)
  }

  var addEntry = function (entryType) {
    var entryProto = $.extend({}, entryType, { amount: entryType.amount || 1 })
    var entryAttributes = _.omit(buildItem(entryProto), _.isUndefined)
    var entryHtml = entriesTemplate.railsTemplate('dental_order_entries').html
    var entryRow = entryHtml.first().nestedFieldSet()

    entryRow.set(entryAttributes)
    container.append(entryHtml)

    togglePriceFields()

    entryRow.init()
    entryRow.recalculate()
    updateIndexes()
    triggerListChanged()
  }

  var addMember = function (entryType, params) {
    var complexRow = params.complexRow

    var memberHtml = complexRow
      .getMemberTemplate()
      .railsTemplate('members').html

    var memberRow = memberHtml.first().nestedFieldSet()
    var memberAmount = params.amount || 1
    var memberAttributes = buildItem($.extend({}, entryType, {
      amount: memberAmount,
    }))

    memberAttributes = cleanAttributes(memberAttributes, 'order_id')

    if (complexRow.get('discount_disabled', 'bool')) {
      memberAttributes.discount_disabled = true
      memberAttributes.discount_value = 0
    }

    memberRow.set(memberAttributes)
    complexRow.appendMemberHtml(memberHtml)

    entryType.entry_type_consumables.forEach(function (consumable) {
      addConsumable(consumable.consumable, {
        amount: consumable.amount * memberAmount,
        entryRow: memberRow,
      })
    })

    if (!params.skipInit) {
      memberRow.hideContent()
      complexRow.init()
      updateIndexes()
      triggerListChanged()
    }
  }

  var addConsumable = function (entryType, params) {
    var entryRow = params.entryRow

    var consumableHtml = entryRow
      .getConsumableTemplate()
      .railsTemplate('consumables').html
    var consumableRow = consumableHtml.first().nestedFieldSet()

    var consumableAttributes = buildItem($.extend({}, entryType, {
      amount: params.amount || 1,
    }))

    $.extend(consumableAttributes, {
      price: 0,
      final_price: 0,
      sum: 0,
      final_sum: 0,
      discount_value: 0,
      discount_sum: 0,
      consumable: true,
    })

    consumableAttributes = cleanAttributes(consumableAttributes,
      'order_id', 'user_id', 'assistant_id',
    )

    consumableRow.set(consumableAttributes)
    entryRow.appendConsumableHtml(consumableHtml)

    entryRow.init()
    entryRow.redraw()
    consumableRow.redraw()
    updateIndexes()
    triggerListChanged()
  }

  var setAttributes = function (opts) {
    container.find('.f-order-entry').each(function (i, e) {
      var row = $(e).nestedFieldSet()
      var newAttrs = _.clone(attributes)
      var currentAttrs = row.get()

      row.set(newAttrs)
    })

    container.find('.entry_row').each(function (i, e) {
      $(e).nestedFieldSet().recalculate()
    })

    triggerListChanged()
  }

  var openHiddenErrors = function (e, data) {
    var entriesErrors = data.errors.entries_attributes
    if (!entriesErrors) return

    var entries = container.find('.entry_row').toArray().map(function (e) {
      return $(e).nestedFieldSet()
    })

    Object.keys(entriesErrors).forEach(function (entryIndex) {
      var entry = entries[parseInt(entryIndex)]
      if (!entry) return

      var entryErrors = entriesErrors[entryIndex]
      var membersErrors = entryErrors.members_attributes

      if (membersErrors) {
        Object.keys(membersErrors).forEach(function (memberIndex) {
          var member = entry.getDataArray('members')[parseInt(memberIndex)]
          let memberErrors = membersErrors[memberIndex]
          let consumableErrors = memberErrors.consumables_attributes
          if (member && consumableErrors) member.showContent()
        })
      } else {
        let consumableErrors = entryErrors.consumables_attributes
        if (consumableErrors) entry.showContent()
      }
    })
  }

  //
  // PubSub subscriptions
  //

  PubSub.on(msgPrefix + 'askAddEntry', function (msg, data) {
    if (!data.selector) return addEntry(data.item)

    var selectorRow = data.selector.closest('tr')
    var complex = selectorRow.data('complex')
    var entry = selectorRow.data('entry')
    var type = selectorRow.data('type')

    addEntry(data.item)
  })

  PubSub.on(msgPrefix + 'askSetAttributes', function (msg, data) {
    attributes = $.extend(
      attributes,
      _.omit($.extend({}, data.attributes), _.isUndefined),
    )
    setAttributes({ force: false })
  })

  PubSub.on(msgPrefix + 'askForceAttributes', function (msg, data) {
    attributes = $.extend(
      attributes,
      _.omit($.extend({}, data.attributes), _.isUndefined),
    )
    setAttributes({ force: true })
  })

  PubSub.on('page.form.' + params.model + '.setNew', init)
  PubSub.on('page.form.' + params.model + '.setEdit', init)
  PubSub.on('page.form.' + params.model + '.submitError', openHiddenErrors)

  //
  // events
  //

  var recalculateSelector =
    '.f-price' +
    '.f-order-entry-amount, .f-order-member-amount, ' +
    '.f-discount-value'

  container.on('keyup change mouseup', recalculateSelector, function () {
    var row = $(this).closest('tr').nestedFieldSet()
    row.recalculate()
    triggerListChanged()
  })

  container.on('keyup change mouseup', '.f-order-entry-price, .f-order-entry-amount', function () {
    var row = $(this).closest('tr').nestedFieldSet()
    var price = parseFloat(row.get('price'))
    if (price < 0) {
      row.set('price', Math.abs(price))
    }
    row.recalculate()
    triggerListChanged()
  })

  container.on('click', '.toggle-content-button', function () {
    var row = $(this).closest('tr').nestedFieldSet()
    row.toggleContent()
  })

  container.on('click', '.f-nested-destroy', function (e) {
    e.preventDefault()
    var row = $(this).closest('tr').nestedFieldSet()
    var entry = row.data('dental_order_entry')
    row.gracefulDestroy()
    updateIndexes()
    if (entry) entry.redraw()
    triggerListChanged()
  })

  return {}
}
