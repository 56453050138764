const whatsappNewClientsDenyDefaultSubscribers = Object.freeze({
  orders: 'page.form.customer_client.modal.showed',
  clients: 'page.form.legal_representative.modal.showed',
  treatment_plans: 'page.form.legal_representative.modal.showed',
})

FormFactory.presets.clientFormModalWindow = (params) => {
  const MODEL_NAME = 'client'

  if (!params) {
    throw new Error('Parameters is missing')
  }

  const clientFormModalWindow = $(params.modalWindow)
  const clientForm = clientFormModalWindow.find(params.form)
  const eventClose = params.eventClose || function () {}
  const config = params.config

  if (!clientFormModalWindow.length || clientFormModalWindow.length > 1) {
    throw new Error('Modal window is missing or more than one')
  }

  if (!clientForm.length || clientForm.length > 1) {
    throw new Error('Client formm is missing or more than one')
  }

  const {
    modal,
    inputMask,
    similarItems,
    clientDiscInputMask,
    remoteForm,
  } = config

  if (
    !modal ||
    !inputMask ||
    !similarItems ||
    !clientDiscInputMask
  ) {
    throw new Error('An insufficient number of a components params')
  }

  FormFactory.modal({
    ...modal,
    window: clientFormModalWindow,
    form: clientForm,
  })

  const similarItemsInst = FormFactory.similarItems(similarItems)

  FormFactory.inputmask({
    ...inputMask,
    form: clientForm,
  })

  clientForm.remote_form({
    ...remoteForm,
    model: MODEL_NAME,
  })

  if (params.config && params.config.companyField) {
    FormFactory.companyField(params.config)
  }

  clientForm.find('.client_disc').inputmask(...clientDiscInputMask)
  // In the document, the identifiers of these fields must be unique. Select2 bug
  clientForm.find('.client_groups_value').select2({ width: '100%' })
  clientForm.find('.client_doctor_id').select2({ width: '100%' })
  clientForm.find('#suggestion_client_address_search').suggestionApi({ object: 'address', scope: clientForm })
  clientForm.find('#client_id_card_who_issue').suggestionApi({ object: 'passport', scope: clientForm })
  clientForm.find('#past_random_data').pastFakeClientData({ scope: clientForm })

  // PUBLIC METHODS
  function manageWindow (remoteFormMethod, modalWindowMethod) {
    clientForm.remote_form(remoteFormMethod)
    clientFormModalWindow.modal(modalWindowMethod)
  }

  const methods = {
    getModalForm: () => clientFormModalWindow,
    manageWindow,
  }

  // EVENTS
  PubSub.subscribe(`page.form.${config.modal.model}.checkForChanges`, (target, callback) => callback())

  // для старых модальных форм включать запрет на отправку wa сообщений, если включена настройка
  const subscribeTarget = whatsappNewClientsDenyDefaultSubscribers[gon.page.controller]
  const settingValue = gon.application.new_clients_deny_default
  if (subscribeTarget && settingValue) {
    PubSub.subscribe(subscribeTarget, (target, callback) => {
      const isNewClient = clientForm.find('#client_surname').val()?.trim() === ''
      isNewClient && clientForm
        .find('#client_deny_whatsapp_messages')
        .val(settingValue)
        .trigger('click')

      settingValue && setTimeout(() => {
        clientForm.find('[aria-conols="customer_client_representative_address"]').parent('li').trigger('click')
      })
    })
  }

  PubSub.subscribe(`page.form.${config.modal.model}.modal.closed`, () => {
    similarItemsInst.resetSimilarItems()
    eventClose()
  })

  clientForm.on('ajax:success', function (event, result) {
    PubSub.emit(`page.form.${config.modal.model}.submitSuccess`, {
      queueLength: 0,
      result,
    })
  })

  clientForm.on('ajax:error', function (event, result) {
    PubSub.emit(`page.form.${config.modal.model}.submitError`, {
      queueLength: 0,
      result,
    })
  })

  return methods
}
