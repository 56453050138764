FormFactory.medicalPolicyField = (params) => {
  if (!params.medicalPolicyField) {
    throw new Error('medicalPolicyField params are empty')
  }

  const {
    form: medicalPolicyForm,
    select,
    button,
    clientId,
    model,
  } = params.medicalPolicyField

  const msgPrefix = `page.form.${model}.medicalPolicyField.`

  button.on('click', function () {
    medicalPolicyForm.newItem({
      kind: 'external',
      client_id: clientId,
    }, true)
  })

  select.on('change', (event) => {
    const id = select.val()

    PubSub.emit(msgPrefix + 'change', {
      select,
      value: id,
    })

    if (!gon.specific.medicalPolicyFieldChanged) {
      PubSub.emit(msgPrefix + 'change', {
        select,
        value: id,
      })

      gon.specific.medicalPolicyFieldChanged = Object.hasOwn(event, 'bubbles')
    }
  })

  Services.pubSub.subscribe('ORDERS.INSURANCE_COMPANY_CLEAR_MEDICAL_POLICY', () => {
    select.val(null)
  })

  medicalPolicyForm.subscribe('submitSuccess', (msg, { item: elem }) => {
    if (elem['usable?']) {
      const newOption = new Option(elem.to_s, elem.id, true, true)
      select.append(newOption).trigger('change')
    } else {
      const msg = I18n.t('policy_been_created_but_not_used_account')
      bootbox.alert(msg)
    }
  })

  select.change()

  return {
  }
}
