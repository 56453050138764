FormFactory.presets.waiting_list = function (params) {
  var isEditingClient = false
  var canManageWaitingList = Services.security.canManageWaitingList
  var item = null
  var appointment = null

  var $formWaitingListModal = $('#form_waiting_list_modal')
  var $addAppointment = $formWaitingListModal.find('.add-appointment')
  var $moveAppointment = $formWaitingListModal.find('.move-appointment')
  var $form = $formWaitingListModal.find('#waiting_list_form')
  var $date = $formWaitingListModal.find('#waiting_list_date')
  var $clinicId = $formWaitingListModal.find('#waiting_list_clinic_id')
  var $additional = $formWaitingListModal.find('#waiting_list_additional')
  var $urgent = $formWaitingListModal.find('#waiting_list_urgent')
  var $client = $form.find('#waiting_list_client_id')
  var $clientForm = $formWaitingListModal.find('#clients_form')
  var $surname = $clientForm.find('#client_surname')
  var $clientSubmit = $clientForm.find('#clients_submit')
  var $dependedAppointmentFiled = $formWaitingListModal.find('#waiting_list_form .depended_appointment')

  $clientForm.find('.search_client_legal_representatives')
    .attr('id', 'client_legal_representatives_waiting_list')

  $moveAppointment.hide() //hide on load in default

  params = $.extend({
    model: 'waiting_list',
    window: $('#form_waiting_list_modal'),
    form: $form,
    fnItemsPath: Routes.waiting_lists_path,
    fnItemPath: Routes.waiting_list_path,
    modal: { customSubmit: true },
    fnSmartSearchLockText (app) {
      return app
    },
  }, params)

  var $editClientBtn = params.window.find('.modal-edit-client')
  var $deleteBtn = params.window.find('.modal-delete')
  var $clearBtn = params.window.find('.modal-clear')
  var $saveBtn = params.window.find('.modal-save')
  var $creator = params.form.find('.fs-appointment-creator')
  var $updator = params.form.find('.fs-appointment-updator')

  var afterChange = params.afterChange || _.noop

  FormFactory.build('client', {
    mode: 'waiting_list',
    model: 'WaitingListClient',
    remoteFormModel: 'client',
    form: $clientForm,
    clientLegalRepresentativeSelector: '.waiting-list-client-legal-representative',
  }).newItem()

  function fetchAppointment (id) {
    blockAddAppointmentButton(true)
    $.ajax({
      type: 'POST',
      url: Routes.fetch_appointment_appointments_path(),
      data: { appointment_id: id },
      success (res) {
        appointment = res
        blockAddAppointmentButton(false)
      },
      error (err) {
        console.log(err)
      },
      complete () {
      },
    })
  }

  function displayStaticData (msg, data) {
    var item = data.item
    item.created_by ? $creator.text(item.created_by) : $creator.text('-')
    item.updated_by ? $updator.text(item.updated_by) : $updator.text('-')
  }

  function lockClientForm () {
    PubSub.emit('page.form.WaitingListClient.lockable.lock')
    $('.waiting-list-column-client-match #service_card_edit_form .medium-editor-element').attr('contenteditable', 'false')
    $('.waiting-list-column-client-match #service_card_edit_form .medium-editor-element').attr('readonly', 'readonly')
    params.window.find('.search_client_legal_representatives').prop('disabled', true)
    params.window.find('.client_doctor_id').prop('disabled', true)
    $editClientBtn.removeAttr('disabled')
    isEditingClient = true
  }

  /**
   * @param {boolean} isEditing
   */
  function setEditingMode (isEditing) {
    isEditingClient = isEditing
  }

  function unlockClientForm () {
    PubSub.emit('page.form.WaitingListClient.lockable.unlock')
    PubSub.emit('page.form.WaitingListClient.similarItems.reset')
    $editClientBtn.attr('disabled', 'disabled')
    $('.waiting-list-column-client-match #service_card_edit_form .medium-editor-element').attr('contenteditable', 'true')
    $('.waiting-list-column-client-match #service_card_edit_form .medium-editor-element').removeAttr('readonly')
    params.window.find('.search_client_legal_representatives').prop('disabled', false)
    params.window.find('.client_doctor_id').prop('disabled', false)
    isEditingClient = false
  }

  function blockForm (block) {
    $saveBtn.loadSpin(block ? 'start' : 'stop')
  }

  function blockAddAppointmentButton (block) {
    $addAppointment.loadSpin(block ? 'start' : 'stop')
    $moveAppointment.loadSpin(block ? 'start' : 'stop')
  }

  PubSub.on('page.form.waiting_list.modal.showed', function () {
    $surname.focus()
  })

  PubSub.on('page.form.waiting_list.setNew', function (msg, data) {
    $deleteBtn.hide()
    $addAppointment.hide()
    $moveAppointment.hide()

    var client

    if (data.item) client = data.item.client

    PubSub.emit('page.form.WaitingListClient.similarItems.reset')

    if (client && client.id) {
      PubSub.emit('page.form.WaitingListClient.askNew', { useClear: true, proto: client })
      lockClientForm()
      PubSub.emit('page.form.WaitingListClient.mutable.askSetEdit', { item: client })
      $client.val(client.id)
      PubSub.emit('page.form.client.emkLastNumber.get')
    } else {
      PubSub.emit('page.form.WaitingListClient.askNew', { useClear: true, proto: client })
      PubSub.emit('page.form.WaitingListClient.mutable.askSetNew')
      PubSub.emit('page.form.WaitingListClient.similarItems.askEnable')
      unlockClientForm()
      PubSub.emit('page.form.client.emkLastNumber.get')
    }

    $('.appointment-client-summary-table').remove()

    displayStaticData(msg, data)
    $('#form_waiting_list_modal #client_client_group_ids').select2({ width: '100%' })
  })

  PubSub.on('page.form.waiting_list.setEdit', function (msg, data) {
    $dependedAppointmentFiled.show()
    $deleteBtn.show()

    if (data.item.depended_appointment) {
      $moveAppointment.show()
      $addAppointment.hide()
    } else {
      $addAppointment.show()
      $moveAppointment.hide()
    }

    item = data.item
    appointment = null
    const client = item.client

    item.appointment_id !== null && fetchAppointment(item.appointment_id)

    PubSub.emit('page.form.WaitingListClient.similarItems.reset')
    PubSub.emit('page.form.WaitingListClient.askNew', { useClear: true, proto: client })

    Services.SGAM.clientToInsert = {...client, waiting_list_id: item.id}

    displayStaticData(msg, data)
    $('#form_waiting_list_modal #client_client_group_ids').select2({ width: '100%' })

    FormFactory.show_client_summary({
      date: item.date,
      user_id: item.user_id,
      client,
      complete: () => lockClientForm(),
    })
  })

  PubSub.on('page.form.waiting_list.modal.clickSave', function () {
    FormFactory.serviceCard(
      '.waiting-list-column-client-match',
      '.waiting-list-column-client',
    )

    blockForm(true)
    if (isEditingClient) {
      PubSub.emit('page.form.waiting_list.askSubmit')
    } else {
      PubSub.emit('page.form.WaitingListClient.askSubmit')
    }
  })

  PubSub.on('page.form.WaitingListClient.submitSuccess', function (msg, data) {
    var client = data.item
    $client.val(client.id)

    lockClientForm()
    PubSub.emit('page.form.waiting_list.askSubmit')
  })

  PubSub.on('page.form.appointment.modal.clickSave', function (msg, data) {
    afterChange()
    blockForm(false)
  })

  PubSub.on('page.form.waiting_list.submitSuccess', function (msg, data) {
    afterChange(data.item)
    blockForm(false)
  })

  PubSub.on('page.form.WaitingListClient.submitError', function (msg, data) {
    blockForm(false)
  })

  PubSub.on('page.form.WaitingListClient.similarItems.selected', function (msg, data) {
    var client = data.item
    $client.val(data.item.id)

    PubSub.emit('page.form.WaitingListClient.askNew', { useClear: true, proto: client })
    lockClientForm()
  })

  PubSub.on('page.form.WaitingListClient.setNew', function (msg, data) {
    console.log(data)
    var client = data.item

    if (client) {
      if (client.masked_phone && !Services.security.canManageClientPhone) {
        $('#form_waiting_list_modal #client_phone').val(client.masked_phone.replace(/[^0-9X]/g, ''))
      }

      if (client.legal_representatives) {
        client.legal_representatives.forEach((legalRepresentative) => {
          $clientForm
            .find('.search_client_legal_representatives')
            .append(`<option value="${legalRepresentative.id}" selected="selected">${legalRepresentative.short_info}</option>`)
        })
      }
    }
  })

  $editClientBtn.on('click', function () {
    unlockClientForm()
    var clientId = parseInt($client.val())
    PubSub.emit('page.form.WaitingListClient.mutable.askSetEdit', { item: { id: clientId } })
    PubSub.emit('page.form.WaitingListClient.similarItems.askDisable')
  })

  $addAppointment.on('click', function () {
    if (item.appointment_id === null) {
      Services.SGAM.invokePublicVisibleScheduleMethod(
        Services.SGAM.public.common.option,
        { mode: $.scheduleGrid.MODE_CLIENT_INSERT })
    } else {
      Services.SGAM.invokePublicVisibleScheduleMethod(
        Services.SGAM.public.common.setActiveAppointment,
        appointment)
      Services.SGAM.invokePublicVisibleScheduleMethod(
        Services.SGAM.public.common.option,
        { mode: $.scheduleGrid.MODE_WAITING_LIST_INSERT })
    }

    PubSub.emit('page.form.waiting_list.modal.close')
  })

  $moveAppointment.on('click', function () {
    Services.SGAM.invokePublicVisibleScheduleMethod(
      Services.SGAM.public.common.setActiveAppointment,
      appointment)
    Services.SGAM.invokePublicVisibleScheduleMethod(
      Services.SGAM.public.common.option,
      { mode: $.scheduleGrid.MODE_WAITING_LIST_MOVE })

    PubSub.emit('page.form.waiting_list.modal.close')
  })

  $deleteBtn.click(function () {
    Utils.askDestroy({
      title: t('client_from_the_waiting_list'),
      url: Routes.waiting_list_path(item.id),
      success (item) {
        PubSub.emit('page.form.waiting_list.modal.close')
        afterChange(item)
        params.onDelete && params.onDelete(item)
      },
    })
  })

  $clearBtn.on('click', function () {
    PubSub.emit('page.form.waiting_list.askNew', { useClear: true })
    unlockClientForm()
    $client.val('')
    PubSub.emit('page.form.WaitingListClient.mutable.askSetNew')
    PubSub.emit('page.form.WaitingListClient.similarItems.askEnable')
  })

  if (!canManageWaitingList) {
    $date.attr('disabled', 'disabled')
    $clinicId.attr('disabled', 'disabled')
    $additional.attr('disabled', 'disabled')
    $urgent.attr('disabled', 'disabled')
  }

  $form.find('#waiting_list_user_id').select2({
    disabled: !canManageWaitingList,
    width: '100%',
    templateResult: Utils.userDropdownTemplate,
    templateSelection: Utils.userDropdownTemplate,
  })

  $form.find('.fn-new-client').on('click', function () {
    PubSub.emit('page.form.WaitingListClient.askNew', { useClear: true })
  })

  $formWaitingListModal.find('#past_random_data').pastFakeClientData()

  $clientSubmit.hide()

  return $.extend(
    { setEditingMode },
    FormFactory.core(params),
    FormFactory.modal(params),
    FormFactory.mutable(params),
    FormFactory.smartSearch(params),
  )
}
