/**
 * edit client compnay inside the modal window
 *
 * @param params
 * @returns {{}}
 */
FormFactory.dentalCompanyField = function (params) {
  var CompanySelect = params.dentalCompanyField.target
  var CompanyForm = $('#dental_company_form')
  var FormCompanyModal = $('#form_dental_company_modal')
  var CreateCompanyBtn
  var SubmitBtn = $('#dental_company_submit_btn')
  var formActive = false
  if (params.dentalCompanyField.parentForm) {
    CreateCompanyBtn = params.dentalCompanyField.parentForm.find('#create_dental_company_btn')
  } else {
    CreateCompanyBtn = $('#create_dental_company_btn')
  }
  if (!CompanyForm.length) {
    throw new Error('dental company form not found on page')
  }

  CompanyForm.find('.form-btns').hide()

  CompanySelect.select2({
    width: '100%',
    language: gon.localization.locale,
    dropdownParent: CompanySelect.parents('.field-with-btn_field')
  })

  CreateCompanyBtn.on('click', function () {
    CompanyForm.remote_form('clear')
    FormCompanyModal.modal('show')
    SubmitBtn.remove()
    formActive = true
  })

  FormCompanyModal.find('.modal-save').click(function () {
    if (formActive) {
      CompanyForm.submit()
    }
  })

  FormCompanyModal.find('.modal-close').click(function () {
    formActive = false
  })

  FormCompanyModal.find('.modal-header-close').click(function () {
    formActive = false
  })

  CompanyForm.remote_form({
    model: 'dental_company',
    onSuccess (elem) {
      formActive = false
      FormCompanyModal.modal('hide')
      var newOption = new Option(elem.title, elem.id, true, true)
      CompanySelect.append(newOption).trigger('change')
    },
  })

  CompanyForm.find('.f-inputmask-phone').inputmasks($.inputmasks.maskOpts)

  return {
  }
}
