FormFactory.presets.store = (params) => {
  params = $.extend({
    model: 'store',
    form: $('#store_form'),
    fnItemPath: Routes.store_path,
    fnItemsPath: Routes.stores_path,
    redirectPath: Routes.new_store_path,
    deleted_entity: t('gentitive.store') + '? ' + t('gentitive.store_extended'),
  }, params)

  const {form} = params
  const composition = Object.assign({},
    FormFactory.core(params),
    FormFactory.inputmask(params),
    FormFactory.redirect2sides(params),
    FormFactory.index_table_updator(params),
    FormFactory.delete_button(params),
  )

  return composition
}
