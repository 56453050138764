import applicationFactory from '../plugins/dynamic_forms/application_factory'

window.EntryTemplateForm = (function () {
  let init
  let clear
  let update
  let show
  let hide

  let _bindHanlders
  let _setJQM
  let _initExternal
  let _getTemplates
  let _modifyForm

  let _handlerSuccessSubmit
  let _handlerGotTemplates
  let _handlerClickTemplate
  let _handlerClickAddTemplate
  let _handlerClickDeleteTemplate
  let _handlerClickCancelTemplate
  let _handleCreateTemplate
  //var _resizeModalFull
  //var _resizeModalSmall

  let _createTemplateLabel
  let _startSpin
  let _endSpin

  const jQM = {}
  let defaults = {}
  let config = {}
  const cache = {}
  const text = {}

  defaults = {
    onSuccess () {},
    onError () {},
    spin: {
      lines: 13, // The number of lines to draw
      length: 20, // The length of each line
      width: 10, // The line thickness
      radius: 30, // The radius of the inner circle
      corners: 1, // Corner roundness (0..1)
      rotate: 0, // The rotation offset
      direction: 1, // 1: clockwise, -1: counterclockwise
      color: '#000', // #rgb or #rrggbb or array of colors
      speed: 1, // Rounds per second
      trail: 60, // Afterglow percentage
      shadow: false, // Whether to render a shadow
      hwaccel: false, // Whether to use hardware acceleration
      className: 'spinner', // The CSS class to assign to the spinner
      zIndex: 2e9, // The z-index (defaults to 2000000000)
      top: '50%', // Top position relative to parent
      left: '50%', // Left position relative to parent
    },
  }

  //================= PUBLIC ======================================
  init = function (params) {
    _setJQM()
    _initExternal()
    _bindHanlders()

    cache.templates = []

    config = $.extend({}, defaults, params)

    config.createPath = function () {
      return Routes.entry_templates_path()
    }
    config.updatePath = function (id) {
      return Routes.entry_template_path(id)
    }

    text.create_template = t('creating_new_ready_response')
    text.update_template = t('editing_ready_answer')
  }

  clear = function () {

  }

  update = function (template_record_id) {
    cache.template_record_id = template_record_id
    jQM.fields.template_record_id.val(template_record_id)
    jQM.form.remote_form('clear')
    jQM.modalHeaderItem.html('')
    cache.mode = 'create'
    _modifyForm(cache.mode, true)
    _getTemplates(template_record_id)
  }

  show = function (template_record_id) {
    update(template_record_id)

    jQM.window.megamodal('show')
    jQM.collapses.form.collapse('hide')
    jQM.collapses.list.collapse('show')
  }

  hide = function () {
    jQM.window.modal('hide')
  }

  // ========================== CORE ===========================

  //todo: need to use real panels! // wtf?

  _setJQM = function () {
    jQM.window = $('#modal_template')
    jQM.headerArea = $('#templates_panel_header')
    jQM.formArea = $('#templates_panel_body')
    jQM.form = $('#entry_template_form')
    jQM.scrollArea = $('.scroll_area_template')

    jQM.btnAddTempalte = $('#add_templates_btn')
    jQM.btnDeleteTemplate = $('#btn_delete_template')
    jQM.btnCancelTemplate = $('#btn_cancel_template')

    jQM.bntCreateProtokolTemplate = $('#protokol-template')

    jQM.formHeader = $('#template_form_header')
    jQM.modalHeaderItem = $('#templates_modal_header')
    jQM.modalContainer = $('#template_window_contaner')

    jQM.templatesList = $('#templates_list')

    jQM.collapses = {}
    jQM.collapses.form = $('#template_form_collapse')
    jQM.collapses.list = $('#template_list_collapse')

    jQM.toggles = {}
    jQM.toggles.form = $('#collapse_form_toggle')
    jQM.toggles.list = $('#collapse_list_toggle')

    jQM.fields = {}
    jQM.fields.data = $('#entry_template_data')
    jQM.fields.entry_type_id = $('#entry_template_entry_type_id')
    jQM.fields.template_record_id = $('#entry_template_template_record_id')
    jQM.fields.kind = $('#entry_template_kind')
  }

  _initExternal = function () {
    jQM.fields.data.dynamicTable({
      editMicroTemplates: false,
      displayExtendButtons: false,
    })
    jQM.form.remote_form({
      model: 'entry_template',
      onSuccess: _handlerSuccessSubmit,
      beforeSend: _startSpin,
      afterSend: _endSpin,
      onError () {
        Notificator.error(t('check_correctness_filing_form'))
      },
    })

    jQM.window.megamodal({
      onResizeFull () {
        const maxHeight = $document.height() - 265
        jQM.scrollArea.css('max-height', maxHeight)
      },
      onResizeNormal () {
        jQM.scrollArea.css('max-height', '')
        //jQM.scrollArea.addClass('scrolled-25em');
      },
    })
  }

  _bindHanlders = function () {
    jQM.templatesList.on('click', '.template_label', _handlerClickTemplate)
    jQM.btnAddTempalte.on('click', _handlerClickAddTemplate)
    jQM.btnDeleteTemplate.on('click', _handlerClickDeleteTemplate)
    jQM.btnCancelTemplate.on('click', _handlerClickCancelTemplate)
    jQM.bntCreateProtokolTemplate.on('click', _handleCreateTemplate)
  }

  _getTemplates = function (template_record_id) {
    _startSpin()
    $.ajax({
      url: '/entry_templates',
      data: {
        template_record_id,
      },
      success: _handlerGotTemplates,
      error () {
        Notificator.error('error with getting templates')
      },
    })
  }

  //==========================HANDLERS ========================

  _handlerSuccessSubmit = function (template, fn) {
    fn()
    cache.templates[template.id] = template
    const newLabel = _createTemplateLabel(template)

    switch (cache.mode) {
      case 'update':
        jQM.templateLabels[template.id].replaceWith(newLabel)
        break
      case 'create':
        jQM.templatesList.append(newLabel)
        break
      default:
        console.error('maybe error')
            // do nothing
    }

    jQM.templateLabels[template.id] = newLabel

    jQM.collapses.form.collapse('hide')
    jQM.collapses.list.collapse('show')
  }

  _handlerGotTemplates = function (result) {
    jQM.templatesList.html('')
    jQM.templateLabels = []

    if (result.length) {
      cache.templates = []
      result.forEach(function (template) {
        cache.templates[template.id] = template
        const label = _createTemplateLabel(template)
        jQM.templateLabels[template.id] = label
        jQM.templatesList.append(label)
      })
    }

    _endSpin()
  }

  _handleCreateTemplate = function () {
    applicationFactory.generate('templates', cache.mode)
  }

  _handlerClickTemplate = function () {
    let id = $(this).data('id')
    id = parseInt(id, 10)
    cache.template = cache.templates[id]
    cache.mode = 'update'
    _modifyForm(cache.mode)
  }

  _handlerClickAddTemplate = function () {
    cache.mode = 'create'
    cache.template = undefined
    _modifyForm(cache.mode)
  }

  _handlerClickDeleteTemplate = function () {
    if (cache.mode !== 'update') {
      throw new Error('button_delete_cant_press_here')
    }

    bootbox.confirm(t('delete_template' + '?'), function (res) {
      if (!res) return

      $.ajax({
        method: 'delete',
        url: config.updatePath(cache.template),
        success (elem) {
          jQM.templateLabels[elem.id].remove()
          cache.mode = 'create'
          _modifyForm(cache.mode, true)

          jQM.collapses.form.collapse('hide')
          jQM.collapses.list.collapse('show')
        },
      })
    })
  }

  _handlerClickCancelTemplate = function () {
    jQM.collapses.form.collapse('hide')
    jQM.collapses.list.collapse('show')
  }

  //========================= RENDER ===========================

  _createTemplateLabel = function (template) {
    const elem = $('<li></li>')
    elem.text(template.title)
    elem.addClass('label')
    elem.addClass('template_label')
    elem.addClass('tooltip-bottom')
    elem.attr('title', template.title)
    elem.data('id', template.id)
    elem.addClass('cut')
    elem.css('max-width', '400px')

    return elem
  }

  _modifyForm = function (mode, silent) {
    jQM.form.remote_form('clear')

    switch (mode) {
      case 'update':
        jQM.form.attr('action', config.updatePath(cache.template.id))
        jQM.form.attr('method', 'patch')
        jQM.form.remote_form('update', cache.template)
        jQM.btnDeleteTemplate.show()
        jQM.formHeader.html(text.update_template)
        break
      case 'create':
        jQM.form.attr('action', config.createPath())
        jQM.form.attr('method', 'post')
        jQM.form.remote_form('clear')
        jQM.btnDeleteTemplate.hide()
        //jQM.fields.data.val(JSON.stringify(cache.entryType.data)).change()
        jQM.formHeader.html(text.create_template)
        break
      default:
        throw new Error('error with mode')
    }

    jQM.fields.kind.val(config.kind)

    jQM.fields.template_record_id.val(cache.template_record_id)

    if (!silent) {
      jQM.collapses.form.collapse('show')
      jQM.collapses.list.collapse('hide')
    }
  }

  _startSpin = function () {
    jQM.modalContainer.css('opacity', 0.1)
    jQM.modalContainer.spin(config.spin)
  }

  _endSpin = function () {
    jQM.modalContainer.css('opacity', 1)
    jQM.modalContainer.spin(false)
  }

  // ========================== return =======================

  return {
    init,
    clear,
    update,
    show,
    hide,
  }
})()
