FormFactory.presets.dental_order = function (conf) {
  const msgPrefix = 'page.form.dental_order.'
  const $table = $('#dental_order_entries_area')
  const $form = $('#dental_order_form')
  const $pasteBtn = $('#paste_from_order_btn')
  const $selectAllBtn = $('#select_all_btn')

  const $pasteTree = $('#paste_from_order_list')
  const pasteModal = $('#modal_paste_from_order_show')
  const $orderSumSpan = $('#dental_order_sum_value')

  const $orderSum = $('#dental_order_sum')

  const $orderDate = $('#dental_order_date')
  const $orderDueDate = $('#dental_order_due_date')

  const $performer = $('#dental_order_user_id')
  const treeContainer = $('#catalog_list')
  const $companySubmit = $('#company_submit_btn')

  // intial values (for edit)

  let orderSum

  $companySubmit.hide()

  const typeSelectColumns = [
    {
      render (item, td) {
        return td
      },
    },
  ]
  const params = $.extend({
    model: 'dental_order',
    form: $form,
    fnItemsPath: Routes.dental_orders_path,
    fnItemPath: Routes.dental_order_path,
    dentalOrderEntryList: {
      container: $table,
    },
    companyField: {
      parentForm: $('#dental_company_form'),
      target: $('#dental_company_company_id'),
    },
    dentalCompanyField: {
      mode: 'modal',
      parentForm: $('#dental_order_form'),
      target: $('#dental_order_dental_company_id'),
    },
    entryTypeSelector: [
      {
        selector: '.f-order-main-selector .entry_type_selector',
        editable: true,
        columns: typeSelectColumns,
        includePrice: true,
      },
      {
        selector: '.f-dynamic-members-selector .entry_type_selector',
        editable: false,
        columns: typeSelectColumns,
        includePrice: true,
      },
      {
        selector: '.f-dynamic-consumables-selector.enabled .entry_type_selector',
        editable: false,
        columns: typeSelectColumns,
        onlyGoods: true,
        includePrice: true,
      },
    ],
    nestedFields: {
      model: 'dental_order_entries',
    },
    inputmask: {
      presets: {
        dental_order_entry: ['decimal', {
          radixFocus: false,
          autoUnmask: true,
          min: 0,
          max: 100,
          allowMinus: false,
          rightAlign: false,
          digits: 5,
        }],
      },
    },
  }, conf)

  treeContainer.fancyEntryTypes({
    onSelect (entryType) {
      PubSub.publish(msgPrefix + 'dentalOrderEntryList.askAddEntry', {
        item: entryType,
      })
    },
  })

  treeContainer.on('fancyEntryTypes.itemLoading.start', () => {
    $table.parent().loadSpin('start')
  })

  treeContainer.on('fancyEntryTypes.itemLoading.end', () => {
    /**
     * See app/assets/javascripts/forms/form_factory/presets/treatment_plan.js
     */
    $table.parent().loadSpin('stop')
  })

  $pasteTree.fancyPasteModal({
    pasteBtn: $pasteBtn,
    pasteModal,
    pasteModalBtn: $('.modal-paste'),
    selectAllBtn: $selectAllBtn,
    url: Routes.order_entries_order_path(gon.specific.order_id || 0),
    onSelect (entry) {
      PubSub.publish(msgPrefix + 'dentalOrderEntryList.askAddEntry', {
        item: entry,
      })
    },
  })
  //
  // pubsub subscriptions
  //

  PubSub.on(msgPrefix + 'entryTypeSelector.selected', function (msg, data) {
    const proto = data.item
    const selector = data.selector

    PubSub.publish(msgPrefix + 'dentalOrderEntryList.askAddEntry', {
      item: proto,
      selector,
    })
  })

  PubSub.on(msgPrefix + 'dentalOrderEntryList.listChanged', function (msg, data) {
    orderSum = data.sum

    $orderSum.val(orderSum)

    $orderSumSpan.text(orderSum.toFixed(2))
  })

  function init (mode) {

  }

  return $.extend({},
    FormFactory.core(params),
    FormFactory.entryTypeSelector(params),
    FormFactory.dentalOrderEntry(params),
    FormFactory.dentalOrderEntryList(params),
    FormFactory.companyField(params),
    FormFactory.dentalCompanyField(params),
    FormFactory.redirect(params),
    FormFactory.currency(params),
    FormFactory.inputmask(params),
    FormFactory.nestedFields(params)
  )
}
