FormFactory.dentalOrderEntry = function () {
  $.NestedFieldSet.register('f-order-nested-entry', {
    name: 'dental_order_entries',
    render () {
      this.updateContentIcon({
        visible: true,
        number: this.getDataArray('consumables')
          .filter(function (e) { return !e.isDeleted() })
          .length,
      })

      if (this.data('locked_from_billing_changes')) this.lockFromBillingChanges()

      this.formatMoneyField('price')
      this.formatMoneyField('sum')
    },

    extend: $.extend({}, FormFactory.dentalOrderEntryCommon(), {
      getMemberTemplate () {
        return this.html().find('._template_members')
      },

      getConsumableTemplate () {
        return this.html().find('._template_consumables')
      },

      appendMemberHtml (html) {
        const membersEnd = this.cache('membersEnd', function () {
          return this.html().nextAll('.f-entry-members-end').first()
        })

        $(html).insertBefore(membersEnd)
      },

      appendConsumableHtml (html) {
        const consumablesEnd = this.cache('consumablesEnd', function () {
          return this.html().nextAll('.f-entry-consumables-end').first()
        })
        $(html).insertBefore(consumablesEnd)
      },

      init () {
        this.recalculate()
        this.redraw()
      },

      // update schema on add, remove or delete member

      recalculate () {
        this.set(this.recalculateMoneyAttrs(this.get()))
        this.redraw()
      },

      gracefulDestroy () {
        this.destroy()
      },

      hideDirectChildren: _.noop,
    }),
  })

  return {}
}
