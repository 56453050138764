import SmsEditor from '@/modules/variables_editor.js'
import { smsCount } from '@/modules/sms/utils.js'
import { CLIENTS_DISPATCH } from '@/modules/sms/editor_presets.js'

export default (params) => {
  const msgPrefix = 'page.form.sms_dispatch'
  const $form = $('#sms_dispatch_form')
  const $modalWindow = $('#sms_dispatch_form_modal')
  const $clientsCounter = $('.dispatch_clients_counter')
  const $approximateSmsCount = $('.approximate_messages_quantity_value')
  const $symbolCount = $form.find('.symbol_count')
  const $editor = $('.text_editor_container')
  const $submit = $('.modal-save')
  let dashboardFilters = Object.create(null)
  let clientsCount = 0

  params = $.extend({
    model: 'sms_dispatch',
    form: $form,
    fnItemsPath: Routes.sms_dispatches_path,
    fnItemPath: Routes.sms_dispatch_path,
    window: $modalWindow,
    msgPrefix,
    modal: { customSubmit: true },
  }, params)

  const sendFormData = (editor) => {
    const text = editor.originalize()
    const type = document.getElementById('sms_dispatch_dispatch_type').value
    $submit.loadSpin('start')
    $.ajax({
      url: Routes.sms_dispatches_path(),
      method: 'POST',
      data: JSON.stringify(
        {
          sms_dispatch: {
            text,
            dashboard_filters: dashboardFilters,
            clients_count: clientsCount,
            dispatch_type: type,
          },
        },
      ),
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      success (dispatch) {
        editor.visualize()
        $submit.loadSpin('stop')
        $modalWindow.modal('hide')
        Notificator.success(T.sms.dispatch_created)
      },
      error (err) {
        console.log(err.responseJSON)
        editor.visualize()
        $submit.loadSpin('stop')
        if (err.responseJSON.created_at) {
          Notificator.error(err.responseJSON.created_at.join('; '))
        }
      },
    })
  }

  const editor = new SmsEditor({
    container: $editor,
    onPreviewChanged (text) {
      const count = smsCount(text)
      $approximateSmsCount.text(count)
      $symbolCount.text(text.length)
    },
    validationCallback (value) {
      $submit.prop('disabled', !value)
    },
    ...CLIENTS_DISPATCH,
  })

  PubSub.on('page.form.sms_dispatch.setNew', function (msgPrefix, data) {
    const { clientsCount: count, filters } = data.item
    dashboardFilters = filters
    clientsCount = count

    $clientsCounter.text(clientsCount)
  })

  PubSub.on('page.form.sms_dispatch.modal.closed', function (msgPrefix, data) {
    editor.reset()
    $approximateSmsCount.text(0)
  })

  $submit.on('click', (e) => {
    if (editor.validate()) {
      bootbox.confirmYN(T.sms.send_dispatch, (res) => {
        if (res) sendFormData(editor)
      })
    }
    e.stopPropagation()
    e.preventDefault()
  })

  return Object.assign({},
    FormFactory.core(params),
    FormFactory.modal(params),
  )
}
