FormFactory.entryTypeField = (params) => {
  if (!params.entryTypeField) throw new Error('entryTypeField params are empty')

  const {
    fastSearch,
    hiddenFieldContainer,
    table,
    entryTypesSum,
  } = params.entryTypeField

  let store = []

  PubSub.on('page.form.' + params.model + '.setEdit', (msg, data) => {
    init(data)
  })

  PubSub.on('page.form.' + params.model + '.setNew', (msg, data) => {
    init(data)
  })

  table.on('click', '.fa-times', (e) => {
    const id = $(e.target).parents('tr').data('id')
    remove(id)
  })

  fastSearch.data('f_smartSearch', {
    url: '/entry_types/search',
    method: 'GET',
    queryParams: {
      user_id: () => params.form.find('#appointment_user_id').val(),
    },
    itemRender (html, item) {
      const lab = item.analysis_laboratory
      const ANALYSIS = 1

      if (lab && item.kind === ANALYSIS) {
        const $lab = $('<span>')
          .addClass('entry-type-selector-laboratory cut')
          .text(lab.title)
        html.append(' ', $lab)
      }

      const number = item.number
      if (number) {
        const $code = $('<span>')
          .addClass('entry-type-selector-number')
          .text(number)
        html.prepend($code, ' ')
      } else {
        const $empty = $('<span>')
          .addClass('entry-type-selector-number')
        html.prepend($empty, ' ')
      }

      const label = item.label
      if (label) {
        const $label = $('<span>')
          .addClass('entry-type-selector-label')
          .text(label)
        html.append($label, ' ')
      }

      const price = item.price
      const priceTxt = price + ' ' + Utils.currencySign()
      const $price = $('<span>')
        .addClass('entry-type-selector-price')
        .text(priceTxt)
      html.append($price, ' ')

      return html
    },
    lockable: false,
    onSelect: (e) => add(e),
    forCurrentClinic: true,
  })

  function init (data) {
    store = data.item.entry_types || []
    redraw()
  }

  function add (e) {
    const exists = ids().includes(e.id)
    if (!exists) {
      store.unshift(e)
      redraw()
      calculateEntryTypesDuration()
    }

    setTimeout(() => {
      fastSearch.val('')
      //alert(e.tip)
    }, 100)
  }

  function remove (id) {
    const index = store.findIndex((e) => {
      return e.id === id
    })
    store.splice(index, 1)
    redraw()
    calculateEntryTypesDuration()
  }

  function redraw () {
    let html = ''
    let hiddenHtml = ''
    let entryTypesTotal = 0

    store.forEach((e) => {
      entryTypesTotal += Utils.currencyToNumber(e.price)

      const price = Utils.numberToLocaleCurrency(Utils.currencyToNumber(e.price))
      html += `<tr data-id="${e.id}">
        <td
          class="cut tooltip-bottom"
          title="${e.title}, ${price}">
          ${e.title}
        </td>
        <td><span class="fad fa-times"></span></td></tr>
      </tr>`
      hiddenHtml += `<input
        value="${e.id}"
        class="text optional hidden"
        type="text"
        name="appointment[entry_type_ids][]"
      >`
    })

    table.html(html)
    if (store.length) {
      entryTypesTotal = Utils.numberToLocaleCurrency(entryTypesTotal)
      entryTypesSum.text(`${T.total}: ${entryTypesTotal}`)
    } else {
      entryTypesSum.text('')
    }
    hiddenFieldContainer.html(hiddenHtml)
  }

  function ids () {
    return store.map((e) => {
      return e.id
    })
  }

  function calculateEntryTypesDuration () {
    const entryDurations = store.map((e) => {
      return e.appointment_duration
    })
    if (!entryDurations.length) return

    const sum = entryDurations.reduce((acc, val) => {
      return acc + val
    })
    if (sum > 0) params.entryTypeField.durationSelectAddCallback(sum)
  }

  return {
  }
}
