FormFactory.presets.document_type = (params) => {
  params = $.extend({
    model: 'document_type',
    form: $('#document_type_form'),
    fnItemPath: Routes.document_type_path,
    fnItemsPath: Routes.document_types_path,
    redirectPath: Routes.new_document_type_path,
    deleted_entity: t('gentitive.document_type'),
  }, params)

  let composition = Object.assign({},
    FormFactory.core(params),
    FormFactory.inputmask(params),
    FormFactory.redirect2sides(params),
    FormFactory.index_table_updator(params),
  )

  // the crutch that does not allow to hide the
  // delete button in the order
  if (params.origin !== 'order') {
    composition = Object.assign(composition, FormFactory.delete_button(params))
  }

  return composition
}
