import AddressMatcher from '../components/company/address_matcher.js'

FormFactory.presets.companyFormModalWindow = (params) => {
  const MODEL_NAME = 'company'

  if (!params) {
    throw new Error('Parameters is missing')
  }

  const modalWindow = $(params.modalWindow)
  const form = modalWindow.find(params.form)
  const components = params.components

  if (!modalWindow.length || modalWindow.length > 1) {
    throw new Error('modal window is missing or more than one')
  }

  if (!form.length || form.length > 1) {
    throw new Error(' form is missing or more than one')
  }

  if (!components) {
    throw new Error('components is missing')
  }

  const {
    modal,
    remoteForm,
  } = components

  FormFactory.modal({
    ...modal,
    window: modalWindow,
    form,
  })

  form.remote_form({
    ...remoteForm,
    model: MODEL_NAME,
  })

  form.find('#suggestion_company_search').suggestionApi({ object: 'company', scope: form })

  const addressFormal = form.find('.address_formal')
  const addressFact = form.find('.address_fact')

  const addressMatcher = new AddressMatcher([
    [
      addressFormal.find('#company_address_formal_index'),
      addressFact.find('#company_address_fact_index'),
    ],
    [
      addressFormal.find('#company_address_formal_country'),
      addressFact.find('#company_address_fact_country'),
    ],
    [
      addressFormal.find('#company_address_formal_region'),
      addressFact.find('#company_address_fact_region'),
    ],
    [
      addressFormal.find('#company_address_formal_area'),
      addressFact.find('#company_address_fact_area'),
    ],
    [
      addressFormal.find('#company_address_formal_city'),
      addressFact.find('#company_address_fact_city'),
    ],
    [
      addressFormal.find('#company_address_formal_street'),
      addressFact.find('#company_address_fact_street'),
    ],
    [
      addressFormal.find('#company_address_formal_house'),
      addressFact.find('#company_address_fact_house'),
    ],
    [
      addressFormal.find('#company_address_formal_flat'),
      addressFact.find('#company_address_fact_flat'),
    ],

    [
      addressFormal.find('#company_address_formal_nsi_russian_subject_id'),
      addressFact.find('#company_address_fact_nsi_russian_subject_id'),
    ],
  ])

  addressFormal.find('#addresses_match').on('input', function () {
    if (this.checked) {
      addressMatcher.copyFormalAddressIntoFact()
      addressMatcher.start()
    } else {
      addressMatcher.stop()
    }

    addressFact.find('#addresses_match').prop('checked', this.checked)
  })

  addressFact.find('#addresses_match').on('input', function () {
    if (this.checked) {
      addressMatcher.copyFactAddressIntoFormal()
      addressMatcher.start()
    } else {
      addressMatcher.stop()
    }

    addressFormal.find('#addresses_match').prop('checked', this.checked)
  })

  function manageWindow (modalWindowMethod) {
    form.remote_form('clear')
    modalWindow.modal(modalWindowMethod)
  }

  // EVENTS
  PubSub.subscribe(`page.form.${components.modal.model}.checkForChanges`, (target, callback) => callback())

  form.on('ajax:success', function (event, result) {
    PubSub.emit(`page.form.${components.modal.model}.submitSuccess`, {
      queueLength: 0,
      result,
    })
  })

  form.on('ajax:error', function (event, result) {
    PubSub.emit(`page.form.${components.modal.model}.submitError`, {
      queueLength: 0,
      result,
    })
  })

  return {
    manageWindow,
  }
}
