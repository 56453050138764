FormFactory.presets.referral = (params) => {
  //var msgPrefix = 'page.form.referral.'

  params = $.extend({
    model: 'referral',
    form: $('#referral_form'),
    fnItemsPath: Routes.referrals_path,
    fnItemPath: Routes.referral_path,
    redirectPath: Routes.new_referral_path,
    window: $('#modal_referral_form'),
    companyField: {
      target: $('#referral_company_id'),
      parentForm: $('#referral_form'),
    },
    deleted_entity: t('gentitive.referral'),
  }, params)

  $('#suggestion_referral_address_search').suggestionApi({object: 'referral_address', scope: $('#referral_form')})
  $('#referral_specialty_ids').select2()
  $('#referral_medical_user_id').select2({
    templateResult: Utils.userDropdownTemplate,
    templateSelection: Utils.userDropdownTemplate,
  })

  const {form} = params
  const composition = Object.assign({},
    FormFactory.core(params),
    FormFactory.companyField(params),
    FormFactory.inputmask(params),
  )

  if (params.mode === 'modal') {
    Object.assign(composition,
      FormFactory.modal(params),
    )
    form.find(':submit').hide()
  } else {
    Object.assign(composition,
      FormFactory.redirect2sides(params),
      FormFactory.index_table_updator(params),
      FormFactory.delete_button(params),
    )
  }

  return composition
}
