FormFactory.presets.call = function (params) {
  var resultForm = $('#call_result_form')

  params = $.extend({
    model: 'call',
    window: $('#modal_call_result'),
    form: resultForm,
    fnItemsPath: Routes.calls_path,
    fnItemPath: Routes.call_path,
    checkForChanges: false,
    megamodalParams: {
      btnResize: false,
    },
  }, params)

  const requiredTag = $('<abbr title="required">*</abbr>')
  if (!$('#call_result_form').find('abbr').length) {
    $('#modal_call_result').find('.required').append(requiredTag)
  }
  if ($('#call_result_form').find('.required').length > 0) {
    $('#modal_call_result').find('.modal-buttons').hide()
  }

  const {form} = params

  return $.extend({},
    FormFactory.core(params),
    FormFactory.modal(params),
    FormFactory.mutable(params),
  )
}
