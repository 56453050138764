FormFactory.referralField = (params) => {
  if (!params.referralField) throw new Error('referralField params are empty')

  var msgPrefix = 'page.form.' + params.model + '.referralField.'
  const {form: referralForm, select, button} = params.referralField

  button.on('click', function () {
    referralForm.newItem({kind: 'external'}, true)
  })

  referralForm.subscribe('submitSuccess', (msg, {item: elem}) => {
    var newOption = new Option(elem.full_name, elem.id, true, true)
    select.append(newOption).trigger('change')
    PubSub.emit(msgPrefix + 'newOption', elem)
  })

  return {
  }
}
