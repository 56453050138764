FormFactory.presets.workplace = (params) => {
  var msg = ''
  if ($('#user_status').text() === 'false') {
    msg = t('gentitive.workplace_have_users')
  } else {
    msg = t('gentitive.workplace')
  }
  params = $.extend({
    model: 'workplace',
    form: $('#workplace_form'),
    fnItemPath: Routes.workplace_path,
    fnItemsPath: Routes.workplaces_path,
    redirectPath: Routes.new_workplace_path,
    deleted_entity: msg,
  }, params)

  const {form} = params
  const composition = Object.assign({},
    FormFactory.core(params),
    FormFactory.inputmask(params),
    FormFactory.redirect2sides(params),
    FormFactory.index_table_updator(params),
    FormFactory.delete_button(params),
  )

  return composition
}
