<template>
  <div class="inline-block w-100">
    <input
      ref="phoneInput"
      type="hidden"
      :name="nameAttr"
      :value="phone"
    >

    <validation-wrapper :errors="phoneErrors">
      <m-phone-input
        v-model="phone"
        :m-fixed-height="false"
        @input="phoneErrors = []"
      />
    </validation-wrapper>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import MPhoneInput from '@/vue_present/_base/MPhoneInput/MPhoneInput.vue'
import { MATCHING_CLIENT } from '@/vue_components/client/MatchingClient/consts'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import ValidationWrapper from '@/vue_components/common/validation_wrapper.vue'

export default defineComponent({
  name: 'LegacyClientPhoneApp',
  components: { ValidationWrapper, MPhoneInput },
  mixins: [ValidationParentMixin],

  props: {
    nameAttr: { type: String, required: true },
  },

  data () {
    return {
      phone: '',
      phoneErrors: [],
    }
  },

  created () {
    PubSub.on(MATCHING_CLIENT.SELECTED, (target, data) => {
      this.phone = data?.item?.phone
    })

    PubSub.on(MATCHING_CLIENT.ERROR, (target, data) => {
      this.phoneErrors = data.result.phone || []
    })
  },
})
</script>
