FormFactory.presets.refuse_result = (params) => {
  params = $.extend({
    model: 'refuse_result',
    form: $('#refuse_result_form'),
    fnItemPath: Routes.refuse_result_path,
    fnItemsPath: Routes.refuse_results_path,
    redirectPath: Routes.new_refuse_result_path,
    deleted_entity: t('gentitive.refuse_result'),
  }, params)

  const {form} = params
  const composition = Object.assign({},
    FormFactory.core(params),
    FormFactory.inputmask(params),
    FormFactory.redirect2sides(params),
    FormFactory.index_table_updator(params),
    FormFactory.delete_button(params),
  )

  return composition
}
