FormFactory.presets.specialty = (params) => {
  params = $.extend({
    model: 'specialty',
    form: $('#specialty_form'),
    fnItemPath: Routes.specialty_path,
    fnItemsPath: Routes.specialties_path,
    redirectPath: Routes.new_specialty_path,
    deleted_entity: t('gentitive.refuse_result'),
  }, params)

  const {form} = params
  const composition = Object.assign({},
    FormFactory.core(params),
    FormFactory.inputmask(params),
    FormFactory.redirect2sides(params),
    FormFactory.index_table_updator(params),
    FormFactory.delete_button(params),
  )

  return composition
}
