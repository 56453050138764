FormFactory.presets.machine = (params) => {
  params = $.extend({
    model: 'machine',
    form: $('#machine_form'),
    fnItemPath: Routes.machine_path,
    fnItemsPath: Routes.machines_path,
    redirectPath: Routes.new_machine_path,
    deleted_entity: t('gentitive.machine'),
  }, params)

  const {form} = params
  const composition = Object.assign({},
    FormFactory.core(params),
    FormFactory.inputmask(params),
    FormFactory.currency(params),
    FormFactory.redirect2sides(params),
    FormFactory.index_table_updator(params),
    FormFactory.delete_button(params),
  )

  return composition
}
