export default class AddressMatcher {
  constructor (addresses) {
    this.addresses =
      addresses.map(([formalAddressSelector, factAddressSelector]) => {
        return [$(formalAddressSelector), $(factAddressSelector)]
      })
  }

  start () {
    this.addresses.forEach(([formalElem, factElem]) => {
      formalElem.on('input', function () {
        factElem.val(this.value)
      })

      factElem.on('input', function () {
        formalElem.val(this.value)
      })
    })
  }

  stop () {
    this.addresses.forEach(([formalElem, factElem]) => {
      formalElem.off('input')
      factElem.off('input')
    })
  }

  copyFormalAddressIntoFact () {
    this.addresses.forEach(([formalElem, factElem]) => {
      factElem.val(formalElem.val())
    })
  }

  copyFactAddressIntoFormal () {
    this.addresses.forEach(([formalElem, factElem]) => {
      formalElem.val(factElem.val())
    })
  }
}
