import AddressMatcher from '../components/company/address_matcher.js'

FormFactory.presets.company = (params) => {
  params = $.extend({
    model: 'company',
    form: $('#company_form'),
    fnItemPath: Routes.company_path,
    fnItemsPath: Routes.companies_path,
    redirectPath: Routes.new_company_path,
    deleted_entity: t('gentitive.company'),
  }, params)

  params.form.find('#suggestion_company_search').suggestionApi({ object: 'company', scope: params.form })

  const addressFormal = params.form.find('#address_formal')
  const addressFact = params.form.find('#address_fact')

  const addressMatcher = new AddressMatcher([
    [
      addressFormal.find('#company_address_formal_index'),
      addressFact.find('#company_address_fact_index'),
    ],
    [
      addressFormal.find('#company_address_formal_country'),
      addressFact.find('#company_address_fact_country'),
    ],
    [
      addressFormal.find('#company_address_formal_region'),
      addressFact.find('#company_address_fact_region'),
    ],
    [
      addressFormal.find('#company_address_formal_area'),
      addressFact.find('#company_address_fact_area'),
    ],
    [
      addressFormal.find('#company_address_formal_city'),
      addressFact.find('#company_address_fact_city'),
    ],
    [
      addressFormal.find('#company_address_formal_street'),
      addressFact.find('#company_address_fact_street'),
    ],
    [
      addressFormal.find('#company_address_formal_house'),
      addressFact.find('#company_address_fact_house'),
    ],
    [
      addressFormal.find('#company_address_formal_flat'),
      addressFact.find('#company_address_fact_flat'),
    ],

    [
      addressFormal.find('#company_address_formal_nsi_russian_subject_id'),
      addressFact.find('#company_address_fact_nsi_russian_subject_id'),
    ],
  ])

  addressFormal.find('#addresses_match').on('input', function () {
    if (this.checked) {
      addressMatcher.copyFormalAddressIntoFact()
      addressMatcher.start()
    } else {
      addressMatcher.stop()
    }

    addressFact.find('#addresses_match').prop('checked', this.checked)
  })

  addressFact.find('#addresses_match').on('input', function () {
    if (this.checked) {
      addressMatcher.copyFactAddressIntoFormal()
      addressMatcher.start()
    } else {
      addressMatcher.stop()
    }

    addressFormal.find('#addresses_match').prop('checked', this.checked)
  })

  const composition = Object.assign({},
    FormFactory.core(params),
    FormFactory.inputmask(params),
    FormFactory.redirect2sides(params),
    FormFactory.index_table_updator(params),
    FormFactory.delete_button(params)
  )

  return composition
}
