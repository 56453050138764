FormFactory.orderEntryMember = function () {
  const GOOD = 5

  $.NestedFieldSet.register('f-order-nested-member', {
    name: 'members',
    render () {
      const complex = this.data('complex')
      const flexiblePrice = gon.application.settings.flexible_price
      const lockedOrder = gon.specific.order_locked
      const banPaidOrder = gon.application.ban_paid_order_attributes

      this.setFieldsLock(
        (complex && complex.get('discount_disabled', 'bool')) ||
         this.get('discount_disabled', 'bool') ||
         lockedOrder,
        'discount_value',
        'discount_reason_id'
      )

      this.setFieldsLock((complex && complex.get('amount', 'int') > 1) || lockedOrder, 'amount')
      this.setFieldsLock(!flexiblePrice || lockedOrder, 'price')

      this.setFieldsLock(
        banPaidOrder && lockedOrder,
        'referral_id',
        'user_id'
      )

      this.setPerformersVisibility(this.getKind() !== GOOD)
      this.updateContentIcon({
        visible: this.getKind() !== GOOD,
        number: this
          .getDataArray('consumables')
          .filter(function (e) { return !e.isDeleted() })
          .length,
      })

      if (this.data('locked_from_billing_changes')) this.lockFromBillingChanges()

      this.updateDiscountTypeBtns(this.getDiscountType())

      this.updateStoreVisibility()
      this.formatMoneyField('price')
      this.formatMoneyField('final_sum')
    },

    extend: $.extend(FormFactory.orderEntryCommon(), {
      init () {
        const member = this
        const complex = this.data('complex')

        this.ensureMaxDiscount()

        const descendedConsumables = this.getDescendedRows({
          start: 'f-member-consumables-start',
          end: 'f-member-consumables-end',
          filter: 'entry_consumable_row',
        })

        descendedConsumables.forEach(function (consumable, index) {
          consumable.setIndex(index)
          consumable.setParentIndex(member.getIndex(), 'members')
          if (complex) consumable.setParentIndex(complex.getIndex(), 'entries')
          consumable.data('entry', member)
          consumable.init()
        })

        const consumableSelector = this
          .html()
          .nextAll('.f-member-consumables-selector-row')
          .first()

        consumableSelector.data('entry', member)
        consumableSelector.data('type', 'consumables')
        this.data('consumableSelector', consumableSelector)

        this.initDataCollection(descendedConsumables, 'consumables')
        this._initDiscountSelector()
        this.updateSchema()
        this.redraw()
      },

      updateSchema () {
        this._updateSchema('consumables', 'consumableSchema')
      },

      lockFromBillingChanges () {
        this._lockFromBillingChanges()
        const notifyChildren = function (elem) { elem.lockFromBillingChanges() }
        this.getDataArray('consumables').forEach(notifyChildren)
        this.data('consumableSelector').hide()
      },

      gracefulDestroy () {
        this.getDataArray('consumables').forEach(function (consumable) {
          consumable.gracefulDestroy()
        })

        this.destroy()
      },

      updateContentItems () {
        this._updateContentItems('consumables', 'consumableSchema')
        this.recalculate()
      },

      updateChildIndexes () {
        this._updateChildIndexes('consumables', {
          entries: this.getIndex(),
          members: this.data('complex').getIndex(),
        })
      },

      showDirectChildren () {
        this.getDataArray('consumables').forEach(function (consumable) {
          if (!consumable.isDeleted()) consumable.html().show()
        })

        this.data('consumableSelector').show()
      },

      hideDirectChildren () {
        this.getDataArray('consumables').forEach(function (consumable) {
          consumable.html().hide()
          consumable.hideContent()
        })

        this.data('consumableSelector').hide()
      },

      getConsumableTemplate () {
        return this.html().find('._template_consumables')
      },

      getConsumablesArray () {
        return Object.values(this.data('consumables') || {})
      },

      appendConsumableHtml (html) {
        const consumablesEnd = this.html().nextAll('.f-member-consumables-end').first()
        $(html).insertBefore(consumablesEnd)
      },

      setZeroSums () {
        const attrs = {
          price: 0,
          sum: 0,
          final_price: 0,
          final_sum: 0,
          discount_sum: 0,
        }

        this.set(attrs)
      },

      recalculate () {
        const attrs = this.recalculateMoneyAttrs(this.get())
        const newAttrs = this.formatAttrs(attrs)

        // price was already changed, no need to update it again
        delete newAttrs.price

        // critical data isn't changed => no need to redraw
        if (this.noCriticalChanges(newAttrs)) return

        this.set(newAttrs)
        this.redraw()
      },
    }),
  })

  return {}
}
