<template>
  <modal
    modal-class="modal-window-select-input-document-dates document-dates"
    :modal-visibility="visible"
    modal-size="md"
    @close="close"
  >
    <template v-slot:header>
      <i class="fad fa-fw fa-calendar-day" />
      <span>{{ t('document_dates') }}</span>
    </template>

    <template v-slot:body>
      <div class="document-dates__date-start date-container">
        <span class="date-container__label">{{ t('date_start') }}</span>
        <el-date-picker
          v-model="dateStart"
          type="date"
          :clearable="false"
          :format="dateFormat"
          :picker-options="datePickerOptions"
          placement="bottom-start"
        />
      </div>

      <div class="document-dates__date-end date-container">
        <span class="date-container__label">{{ t('date_end') }}</span>
        <el-date-picker
          v-model="dateEnd"
          type="date"
          :clearable="false"
          :format="dateFormat"
          :picker-options="datePickerOptions"
          placement="bottom-start"
        />
      </div>
    </template>

    <template #footer-right>
      <button
        class="btn btn-sm btn-success btn-with-icon modal-add-entries"
        @click="saveDocumentDates"
      >
        <span class="btn-with-icon_icon fad fa-fw fa-plus" />
        <span class="btn-with-icon_text">{{ t('add') }}</span>
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from '@/vue_components/modal.vue'

export default {
  name: 'ModalWindowInputDocumentDates',

  components: {
    Modal,
  },

  props: {
    datePickerOptions: {
      type: Object,
      default: () => ({}),
    },

    dateFormat: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    visible: false,
    dateStart: new Date(),
    dateEnd: '',
  }),

  beforeDestroy () {
    this.$pubSub.reset('MODAL_WINDOW_INPUT_DOCUMENT_DATES.VISIBLE')
  },

  mounted () {
    this.$pubSub.subscribe('MODAL_WINDOW_INPUT_DOCUMENT_DATES.VISIBLE', (visible) => {
      this.visible = visible
      this.legalRepresentativeIds = []
    })
  },

  methods: {
    close () {
      this.visible = false
      this.$pubSub.emitAsync('MODAL_WINDOW_INPUT_DOCUMENT_DATES.CLOSE')
    },

    saveDocumentDates () {
      this.$pubSub.emitAsync('MODAL_WINDOW_INPUT_DOCUMENT_DATES.DOCUMENT_DATES', {
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      })

      this.close()
    },
  },
}
</script>
