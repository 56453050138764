import applicationFactory from '../../../plugins/dynamic_forms/application_factory'

FormFactory.entryMember = function (params) {
  $.NestedFieldSet.register('f-entry-nested-member', {
    name: 'members',

    render () {
      this.updateContentIcon({
        visible: this.getKindString() !== 'good',
        number: this.getDataArray('consumables')
          .filter(function (e) { return !e.isDeleted() })
          .length,
      })

      if (this.data('locked_from_billing_changes')) this.lockFromBillingChanges()

      if (this.getKindString() !== 'good') {
        const state = this.field('state')
        state.select2Buttons({ noEmpty: true })
        state.change()
      } else {
        this.html().find('.entry_members_state').hide()
      }
    },

    extend: $.extend(FormFactory.orderEntryCommon(), {
      init () {
        const member = this
        const consumableSelector = this.getChildRow('.f-member-consumables-selector-row')
        this.data('consumableSelector', consumableSelector)
        consumableSelector.data('entry', this)

        this.ensureMaxDiscount()

        const descendedConsumables = this.getDescendedRows({
          start: 'f-member-consumables-start',
          end: 'f-member-consumables-end',
          filter: 'f-entry-nested-consumable',
        })

        descendedConsumables.forEach(function (consumable, index) {
          consumable.setIndex(index)
          consumable.setParentIndex(member.getIndex(), 'members')
          consumable.data('entry', member)
          consumable.init()
        })

        this.initDataCollection(descendedConsumables, 'consumables')
        this.updateSchema()
        this.redraw()
      },

      updateSchema () {
        this._updateSchema('consumables', 'consumableSchema')
      },

      gracefulDestroy () {
        this.getDataArray('consumables').forEach(function (consumable) {
          consumable.gracefulDestroy()
        })

        this.destroy()
      },

      openProtocolModal () {
        applicationFactory.generate('complexMember', false, {
          member: this,
        })
      },

      updateContentItems () {
        this._updateContentItems('consumables', 'consumableSchema')
      },

      lockFromBillingChanges () {
        this._lockFromBillingChanges()
        const notifyChildren = function (elem) { elem.lockFromBillingChanges() }
        this.getDataArray('consumables').forEach(notifyChildren)
        this.data('consumableSelector').hide()
      },

      showDirectChildren () {
        this.data('consumableSelector').show()
        this.getDataArray('consumables').forEach(function (consumable) {
          if (consumable.isDeleted()) return
          consumable.html().show()
        })
      },

      hideDirectChildren () {
        this.data('consumableSelector').hide()
        this.getDataArray('consumables').forEach(function (consumable) {
          consumable.html().hide()
        })
      },

      getConsumableTemplate () {
        return this.html().find('._template_consumables')
      },

      appendConsumableHtml (html) {
        const consumablesEnd = this.getChildRow('.f-member-consumables-end')
        html.insertBefore(consumablesEnd)
      },
    }),
  })

  return {}
}
