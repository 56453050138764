import DurationSelector from '@/vue_components/duration_selector/duration_selector.js'
import { shortInfoText } from '@/modules/sms/utils.js'

FormFactory.presets.appointment = function (params) {
  var fallbackData = {}
  var isEditingClient = false

  var REPEAT_RESERVATION_TIMEOUT = 1000
  var START_RESERVATION_DELAY = 1

  var reservationRepeater
  var reservationDelayer
  var reservationParams

  var referralForm = FormFactory.build('referral', { mode: 'modal' })

  params = $.extend({
    model: 'appointment',
    window: $('#modal_form_appointment_edit'),
    form: $('#appointment-form'),
    fnItemsPath: Routes.appointments_path,
    fnItemPath: Routes.appointment_path,
    referralField: {
      form: referralForm,
      button: $('#create_referal_btn'),
      select: $('#appointment_referral_id'),
    },
    entryTypeField: {
      fastSearch: $('.f-settings-appointment-entry_type-search'),
      hiddenFieldContainer: $('#entry_type-hidden-field-container'),
      table: $('#appointment-form-entry_types'),
      entryTypesSum: $('#appointment-form-entry-types-sum'),
    },
    modal: {
      customSubmit: true,
    },
    megamodalParams: {
      btnResize: true,
    },
  }, params)

  var form = params.form
  var $modalFormAppointmentEdit = $('#modal_form_appointment_edit')
  var $clientForm = $('#clients_creation_form')

  var $client = form.find('#appointment_client_id')
  var $user = $clientForm.find('.fs-appointment-user')
  var $date = $clientForm.find('.fs-appointment-date')
  var $time = $clientForm.find('.fs-appointment-time')
  var $creator = form.find('.fs-appointment-creator')
  var $updator = form.find('.fs-appointment-updator')
  var $surnameValueContainer = $('#surname_value_container')
  var $surname = $('#client_surname')
  var $editClientBtn = params.window.find('.modal-edit-client')
  var $smsNotifiedData = form.find('.sms_notified_time')
  var $smsRemindedData = form.find('.sms_reminded_time')
  var $smsConfirmData = form.find('.sms_confirmed_time')
  var $clinicId = form.find('#appointment_clinic_id')

  $modalFormAppointmentEdit
    .find('#clients_form')
    .find('.search_client_legal_representatives')
    .attr('id', 'client_legal_representatives_appointment')

  FormFactory.build('client', {
    mode: 'appointment',
    model: 'appointmentClient',
    remoteFormModel: 'client',
    form: $modalFormAppointmentEdit.find('#clients_form'),
    clientLegalRepresentativeSelector: '.appointment-client-legal-representative',
  }).newItem()

  form.find('.fn-new-client').on('click', function () {
    PubSub.emit('page.form.appointmentClient.askNew', { useClear: true })
  })

  $editClientBtn.on('click', function () {
    unlockClientForm()
    var clientId = parseInt($client.val())
    PubSub.emit('page.form.appointmentClient.mutable.askSetEdit', {
      item: { id: clientId },
    })
    PubSub.emit('page.form.appointmentClient.similarItems.askDisable')
    toggleRequiredAttributes(true)
  })

  $surnameValueContainer
    .append('<div class="btn btn-primary fad fa-caret-right switch_right_container_btn switch_right_container_btn_on">')
    .append('<div class="btn btn-primary fad fa-caret-left switch_right_container_btn" style="display:none">')

  $('.switch_right_container_btn').click(function () {
    $('.switch_right_container_btn').toggle()
    $('.appointment-column-client').toggleClass('show_right_form_container')
    $('.appointment-column-client-match').toggle()
  })

  function toggleRequiredAttributes (flag) {
    const elementsRequired = $(
      `#modal_form_appointment_edit label[for="client_name"],
       #modal_form_appointment_edit label[for="client_birthdate"],
       #modal_form_appointment_edit label[for="client_sex_id"]`,
    )

    if (flag && elementsRequired.find('abbr').length === 0) {
      const requiredTag = $('<abbr title="required">*</abbr>')
      elementsRequired.append(requiredTag)
    } else if (!flag) {
      elementsRequired.find('abbr').remove()
    }
  }

  //**************************************************************************
  const durationHiddenInput = $('input#appointment_duration')
  const maxExistingDuration = Math.max(...gon.specific.appointment_durations)
  const minExistingDuration = Math.min(...gon.specific.appointment_durations)

  const durationParams = {
    mountElId: '#duration-select-container-vue',
    durations: gon.specific.appointment_durations,
    durationHiddenInput,
    maxExistingDuration,
    minExistingDuration,
    updateReservation,
  }

  const durationSelector = DurationSelector(durationParams)

  params.entryTypeField.durationSelectAddCallback = durationSelector.set
  /***************************************************************************
   *          Form special hacks =)
   **************************************************************************/

  var displayStaticData = function (msg, data) {
    var app = data.item
    var avatarUrl = Routes.user_avatar_path(app.user_id, { tag: $(data.element).attr('data-avatar-tag'), version: 'thumb16' })
    let dateCondition = Utils.formatClosestDates(app.date, 'ddd, DD MMMM')

    $user.html('<span><img src="' + avatarUrl + '" class="avatar-select2-option-img">' + app.user.short_name + '</span>')
    // $user.text(app.user.short_name)

    $date.text(dateCondition.title)
    $date.addClass(dateCondition.className)
    $time.text(app.time)

    updateAppointmentHistory(app)
  }

  var updateAppointmentHistory = function (app) {
    $creator.text('')
    $updator.text('')

    if (!app.id) return
    var createDate = moment(app.created_at).format(Utils.momentDateTimeFormat)
    var updateDate = moment(app.updated_at).format(Utils.momentDateTimeFormat)

    var createdText = app.created_by ? app.created_by.short_name : '-'
    var updatedText = app.updated_by ? app.updated_by.short_name : '-'

    $creator.text(createDate + ' ' + createdText)

    if (createDate !== updateDate) {
      $updator.text(updateDate + ' ' + updatedText)
    }
  }

  var updateSmsData = function (app = {}) {
    if (!gon.application.sms_module_enabled) return
    $.ajax({
      method: 'GET',
      url: Routes.last_for_appointment_sms_messages_path(),
      data: { appointment_id: app.id },
      success (result) {
        $smsNotifiedData.text(shortInfoText(result.notify))
        $smsRemindedData.text(shortInfoText(result.remind))
        $smsConfirmData.text(shortInfoText(result.confirmation))
      },
      error: (error) => console.log(error),
    })
  }

  var lockClientForm = function () {
    PubSub.emit('page.form.appointmentClient.lockable.lock')
    $('#service_card_edit_form').find('.medium-editor-element').attr('readonly', 'readonly')
    $('#service_card_edit_form').find('.medium-editor-element').attr('contenteditable', 'false')
    $('#clients_creation_form').find('#client_doctor span').css({ 'backgroundColor': '#efefef' })
    $('#send_data_to_egisz')
      .attr('readonly', 'readonly')
      .on('click', (e) => e.preventDefault())

    $modalFormAppointmentEdit.find('.select2-client_legal_representative input').attr('readonly', 'readonly')
    $modalFormAppointmentEdit.find('.search_client_legal_representatives').prop('disabled', true)

    params.window.find('.client_doctor_id').prop('disabled', true)
    $editClientBtn.removeAttr('disabled')
    isEditingClient = true

    // 7jt1ev (10) - кнопка "подключить модуль" всегда доступна
    let $connectModuleBtn = $('.js_client_widget_disabled_module')
    $connectModuleBtn.removeAttr('readonly')
    $connectModuleBtn.removeClass('f-readonly')
    $connectModuleBtn.parent('.f-field-container').removeAttr('readonly')
    $connectModuleBtn.parent('.f-field-container').removeClass('f-readonly')
  }

  var unlockClientForm = function () {
    PubSub.emit('page.form.appointmentClient.lockable.unlock')
    PubSub.emit('page.form.appointmentClient.similarItems.reset')
    $('#service_card_edit_form').find('.medium-editor-element').removeAttr('readonly')
    $('#service_card_edit_form').find('.medium-editor-element').attr('contenteditable', 'true')
    $('#clients_creation_form').find('#client_doctor span').css({ 'backgroundColor': '#fff' })
    $('#send_data_to_egisz')
      .removeAttr('readonly')
      .off('click')

    $modalFormAppointmentEdit.find('.select2-client_legal_representative input').removeAttr('readonly')
    $modalFormAppointmentEdit.find('.search_client_legal_representatives').prop('disabled', false)

    params.window.find('.client_doctor_id').prop('disabled', false)
    $editClientBtn.attr('disabled', 'disabled')
    isEditingClient = false
  }

  var updateFallbackData = function (attrs) {
    var clientGroupIds = attrs.client ? attrs.client.client_group_ids : null
    fallbackData = {
      user: attrs.user,
      _worktime: attrs._worktime,
      date: attrs.date,
      time: attrs.time,
      user_id: attrs.user_id,
      clientGroupIds,
      appointment_source_id: attrs.appointment_source_id,
    }
  }

  var prepareUI = function () {
    $('#client_client_group_ids').select2({ width: '100%' })
    $('#client_doctor_id').select2({ width: '100%' })
  }

  /**
   * Sets referral select to provided referral if presented
   *
   * @param {{id: number, full_name: string}} referral
   */
  const updateReferral = (referral) => {
    const $referral = $('#appointment_referral_id')

    if (referral) {
      const hasReferral = $referral
        .find('option')
        .filter((i, option) => option.value === referral.id.toString()).length
      if (!hasReferral) {
        const option = new Option(referral.full_name, referral.id.toString(), true, true)
        $referral.append(option).trigger('change')
      } else {
        $referral.val(referral.id.toString()).trigger('change')
      }
    }
  }

  var prepareForm = function (msg, data) {
    updateReferral(data.item.referral)
    updateFallbackData(data.item)
    displayStaticData(msg, data)

    durationSelector.adjustDuration(data.item)
    prepareUI()
  }

  var startReservationRepeater = function () {
    notifyReservation()

    // make sure all timeouts cleared
    clearInterval(reservationRepeater)
    reservationRepeater = setInterval(
      notifyReservation,
      REPEAT_RESERVATION_TIMEOUT,
    )
  }

  var startTimeResevation = function (opts) {
    // if we already reserving, no more reservations
    if (reservationParams) stopTimeReservation()

    reservationParams = {
      id: Utils.newGUID(),
      date: opts.date,
      time: opts.time,
      user_id: opts.user_id,
      duration: opts.duration,
      initiator_id: gon.application.current_user.id,
      clinic_id: gon.application.current_clinic.id,
    }
    startReservationRepeater()
  }

  function updateReservation () {
    if (!reservationParams) return // if we not reseving
    reservationParams.duration = durationSelector.current
    startReservationRepeater()
  }

  var stopTimeReservation = function () {
    clearInterval(reservationDelayer)
    clearInterval(reservationRepeater)

    Services.SGAM.invokePublicVisibleScheduleMethod(
      Services.SGAM.public.common.cancelReserve,
      reservationParams)

    reservationParams = null
  }

  var notifyReservation = function () {
    // some accidntal calls without reservation
    if (!reservationParams) return

    Services.SGAM.invokePublicVisibleScheduleMethod(
      Services.SGAM.public.common.reserve,
      reservationParams)
  }

  Services.pubSub.subscribe('CLINIC_SWITCH.BEFORE_PAGE_REFRESH', stopTimeReservation)

  //
  // events
  //

  $('#appointment_dental_order_id').val(gon.application.dental_order_id)

  params.window.find('.modal-clear').on('click', function () {
    toggleRequiredAttributes(false)
    PubSub.emit('page.form.appointment.askNew', {
      useClear: true,
      proto: fallbackData,
    })
    unlockClientForm()
    $client.val('')
    PubSub.emit('page.form.appointmentClient.mutable.askSetNew')
    PubSub.emit('page.form.appointmentClient.similarItems.askEnable')
    $('.appointment-client-summary-table').remove()
  })

  params.window.find('#past_random_data').pastFakeClientData()

  //
  // pubsub
  //

  PubSub.on('page.form.appointment.setNew', function (msg, data) {
    var client = data.item.client
    $('.schedule-grid-appointment-move').hide()
    $smsNotifiedData.text('-')
    $smsRemindedData.text('-')
    $smsConfirmData.text('-')
    $clinicId.val(gon.application.current_clinic.id)
    //pick appointment_type default value
    if (gon.specific.default_appointment_type_id) {
      $('#appointment_appointment_type_id').val(gon.specific.default_appointment_type_id).trigger('change')
    }
    PubSub.emit('page.form.appointmentClient.similarItems.reset')

    if (gon.specific.default_attraction_source_id) {
      $('#appointment_attraction_source_id').val(gon.specific.default_attraction_source_id).trigger('change')
    }

    if (client && client.id) {
      PubSub.emit('page.form.appointmentClient.askNew', { useClear: true, proto: client })
      lockClientForm()
      PubSub.emit('page.form.appointmentClient.mutable.askSetEdit', { item: client })
      $client.val(client.id)
      FormFactory.show_client_summary(data.item)
    } else {
      PubSub.emit('page.form.appointmentClient.askNew', { useClear: true, proto: client })
      PubSub.emit('page.form.appointmentClient.mutable.askSetNew')
      PubSub.emit('page.form.appointmentClient.similarItems.askEnable')
      unlockClientForm()
      PubSub.emit('page.form.client.emkLastNumber.get')
    }

    $('#appointment_remind_before_date').prop('checked', gon.specific.sms_remind_default)
    $('#appointment_notify_after_save').prop('checked', gon.specific.sms_notify_default)
    $('#appointment_send_sms_confirmation').prop('checked', gon.specific.sms_confirmation_default)

    $('.appointment-client-summary-table').remove()

    prepareForm(msg, data)
    $('.appointment_add_to_waiting_list').removeClass('f-readonly')
  })

  PubSub.on('page.form.appointment.setEdit', function (msg, data) {
    $clinicId.val(data.item.clinic_id)
    if (data.item.appointment_source_id === 1) {
      displayStyleConfirmedByAdministrator(false)
      displayStyleClientComment(false)
    } else {
      displayStyleConfirmedByAdministrator(true)
      displayStyleClientComment(true)
    }
    prepareForm(msg, data)
    updateSmsData(data.item)
    var client = data.item.client
    data = {
      client: data.item.client,
      user_id: fallbackData.user_id,
      date: fallbackData.date,
      complete: () => lockClientForm(),
    }
    $('.schedule-grid-appointment-move').show()
    PubSub.emit('page.form.appointmentClient.similarItems.reset')
    PubSub.emit('page.form.appointmentClient.askNew', { useClear: true, proto: client, govno: true })
    FormFactory.show_client_summary(data)

    //disable checkbox editing
    $('.appointment_add_to_waiting_list').addClass('f-readonly')
  })

  PubSub.on('page.form.appointment.modal.clickSave', function () {
    // check allow to record client from black list
    if ($('.appointment-client-summary-table').find('.label-black').html() &&
        gon.application.deny_record_client_from_blacklist) {
      bootbox.alert(t('client_in_blacklist_record_deny'))
    } else {
      // form.find('#appointment_clinic_id').val(gon.application.current_clinic.id)

      FormFactory.serviceCard(
        '.appointment-column-client-match',
        '.appointment-column-client',
      )
      PubSub.emit('page.form.appointment.modal.blockButtons')
      if (isEditingClient) {
        PubSub.emit('page.form.appointment.askSubmit')
      } else {
        askCreateClient()
      }
    }
  })

  function displayStyleConfirmedByAdministrator (show) {
    $('.appointment_status .select2Buttons')
      .find(`li:contains(${T.enums.appointment.status.confirmed_by_administrator})`)
      .get(0)
      .style
      .display = show ? 'inline-block' : 'none'
  }

  function displayStyleClientComment (show) {
    $('.client_comment').css('display', show ? 'flex' : 'none')
    $('#appointment_client_comment').attr('disabled', 'disabled')
  }

  function askCreateClient () {
    let similarClients = params.window.find('.f-si-item')
    if (similarClients.length > 0) {
      bootbox.confirmYN(t('reception.similar_client_confirmation'), function (res) {
        if (res) {
          createClient()
        } else {
          PubSub.emit('page.form.appointment.modal.unblockButtons')
          PubSub.emit('page.form.appointmentClient.similarItems.search')
        }
      })
    } else {
      createClient()
    }
  }

  function createClient () {
    PubSub.emit('page.form.appointmentClient.askSubmit')
  }

  PubSub.on('page.form.appointment.modal.modal.showed', function () {
    $('.appointment-column-client').removeClass('show_right_form_container')
    $('.appointment-column-client-match').show()

    $('#clients_form .nav-tabs').find('li').removeClass('active').first().addClass('active')
    $('#clients_form .tab-content').find('.tab-pane').removeClass('active').first().addClass('active')

    PubSub.emit('page.form.appointment.modal.unblockButtons')
    $surname.focus()

    $('#client_client_group_ids').val(fallbackData.clientGroupIds).trigger('change')
  })

  PubSub.on('page.form.appointmentClient.submitSuccess', function (msg, data) {
    var client = data.item

    $client.val(client.id)
    lockClientForm()
    PubSub.emit('page.form.appointment.askSubmit')
  })

  PubSub.on('page.form.appointmentClient.submitError', function (msg, data) {
    PubSub.emit('page.form.appointment.modal.unblockButtons')
  })

  PubSub.on('page.form.appointment.setNew', function (msg, data) {
    $('#appointment_dental_order_id').val(gon.application.dental_order_id)
    displayStyleConfirmedByAdministrator(false)
    displayStyleClientComment(false)
    reservationDelayer = setTimeout(function () {
      startTimeResevation(data.item)
    }, START_RESERVATION_DELAY)
  })

  PubSub.on('page.form.appointment.modal.startClosing', stopTimeReservation)

  PubSub.on('page.form.appointment.modal.startClosing', () => {
    $.notifyClose('top-right')
    window.Emessage.closeAll()
    toggleRequiredAttributes(false)
  })

  PubSub.on('page.form.appointmentClient.similarItems.selected', function (msg, data) {
    var client = data.item
    $client.val(data.item.id)

    data = {
      client: data.item,
      user_id: fallbackData.user_id,
      date: fallbackData.date,
    }

    PubSub.emit('page.form.appointmentClient.askNew', { useClear: true, proto: client })
    lockClientForm()

    FormFactory.show_client_summary(data)
  })

  PubSub.on('page.form.appointmentClient.setNew', function (msg, data) {
    console.log(data)
    var client = data.item

    $('.search_client_legal_representatives option').remove()

    if (client) {
      if (client.masked_phone && !Services.security.canManageClientPhone) {
        $('#client_phone').val(client.masked_phone.replace(/[^0-9X]/g, ''))
      }

      if (client.legal_representatives) {
        client.legal_representatives.forEach((legalRepresentative) => {
          $modalFormAppointmentEdit
            .find('.search_client_legal_representatives')
            .append(`<option value="${legalRepresentative.id}" selected="selected">${legalRepresentative.short_info}</option>`)
        })
        $('.search_client_legal_representatives').trigger('change')
      }

      $('#client_client_group_ids').val(client.client_group_ids).trigger('change')
      var address = client.address
      var idCard = client.id_card

      if (address) {
        Object.keys(address).forEach(function (item) {
          $('#client_address_' + item).val(address[item])
        })
      }

      if (idCard) {
        Object.keys(idCard).forEach(function (item) {
          if (item === 'issue_date') {
            idCard[item] = Utils.dateRubyToJS(idCard[item])
            $('#client_id_card_' + item).datepicker('setDate', idCard[item])
          } else {
            $('#client_id_card_' + item).val(idCard[item])
          }
        })
      }
    }

    $surname.focus()
  })

  return $.extend({},
    FormFactory.core(params),
    FormFactory.modal(params),
    FormFactory.mutable(params),
    FormFactory.referralField(params),
    FormFactory.entryTypeField(params),
    FormFactory.smartSearch(params),
  )
}
