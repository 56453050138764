const individualCustomerControllers = ['orders']
export const isIndividualCustomerController = (value) => individualCustomerControllers.includes(value)

export const MATCHING_CLIENT = Object.freeze({
  SELECTED: 'page.form.undefined.similarItems.selected',
  ERROR: 'page.form.legal_representative.submitError',
  SUCCESS: 'page.form.legal_representative.submitSuccess',
  CUSTOMER_ERROR: 'page.form.customer_client.submitError',
  CUSTOMER_SUCCESS: 'page.form.customer_client.submitSuccess',
})

export const SHOWED_MODALS = Object.freeze({
  APPOINTMENT: 'page.form.appointment.modal.showed',
  WAITING_LIST: 'page.form.waiting_list.modal.showed',
  CLIENT: 'page.form.client.modal.showed',
  CUSTOMER_CLIENT: 'page.form.customer_client.modal.showed',
})

export const PUBSUB_CLOSED_EVENTS = Object.freeze({
  CLIENT: 'page.form.client.modal.closed',
  CUSTOMER_CLIENT: 'page.form.customer_client.modal.closed',
})
