FormFactory.presets.companyType = (params) => {
  params = {
    model: 'company_type',
    form: $('#company_type_form'),
    fnItemPath: Routes.company_type_path,
    fnItemsPath: Routes.company_types_path,
    redirectPath: Routes.new_company_type_path,
    deleted_entity: T.company_type,
    errorCallback (err) {
      if (!err.responseJSON) return

      let message = `${T.was_error_deleting}. `

      const error = err.responseJSON.there_are_dependent_companies

      if (error) {
        message += `${T.companies}: `
        message += error[0].join(', ')
        message += ` ${T.companies_depend_on_type}`
      }

      return message
    },
    ...params,
  }

  return {
    ...FormFactory.core(params),
    ...FormFactory.inputmask(params),
    ...FormFactory.redirect2sides(params),
    ...FormFactory.index_table_updator(params),
    ...FormFactory.delete_button(params),
  }
}
