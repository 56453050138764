FormFactory.machines = function (params) {
  let field = params.machines.field

  if (!field) throw new Error('[machines.field] not passed')

  const populate = (msg, data) => {
    reset()
    const entry = data.item || {}
    const machineIds = entry.machine_ids || []

    field.val(machineIds).change()
  }

  const reset = () => {
    field.val(null).change()
  }

  PubSub.on('page.form.' + params.model + '.setEdit', populate)
  PubSub.on('page.form.' + params.model + '.setNew', reset)

  return {

  }
}
