export default (modalWindow, form) => ({
  modalWindow,
  form,
  components: {
    modal: {
      model: 'customer_company',
      window: modalWindow,
      form,
    },
    remoteForm: {
      onSuccess () {},
      onError () {},
    },
  },
})
