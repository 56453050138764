// component for selecting entries in modal window
FormFactory.entryTypeSelector = function (params) {
  var msgPrefix = 'page.form.' + params.model + '.entryTypeSelector.'

  var config = params.entryTypeSelector
  var configs = $.isArray(config) ? config : [config]

  function publishSelect (item, selector) {
    PubSub.publish(msgPrefix + 'selected', {
      item,
      selector,
    })
  }

  function initPlugin () {
    configs.forEach(function (config) {
      // refresh stored elements in jquer object
      var selector
      if (typeof config.selector === 'string') {
        selector = $(config.selector)
      } else {
        selector = $(config.selector.selector)
      }
      var opts = $.extend(true, {}, config, {
        onSelect: publishSelect,
      })

      selector.entryTypeSelector(opts)
    })
  }

  PubSub.on('page.form.' + params.model + '.updateComponents', initPlugin)

  // FIXME: needs a fix for multiple selectors
  PubSub.on(msgPrefix + 'askLock', (msg, {text}) => {
    configs.forEach(({selector}) => selector.entryTypeSelector('lock', text))
  })

  PubSub.on(msgPrefix + 'askUnlcok', () => {
    configs.forEach(({selector}) => selector.entryTypeSelector('clear'))
  })

  initPlugin()

  return {}
}
