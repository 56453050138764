import { createVueApp } from '@/vue_components/create_vue_app'
import LegacyClientPhoneApp from '@/vue_apps/ClientsModule/components/LegacyClientPhone/LegacyClientPhoneApp.vue'

const clientPhoneApps = {}

export const createLegacyClientPhoneApp = (parentForm, elAttributeName = 'client[phone]') => {
  if (!elAttributeName) { return }

  const selector = `${parentForm} [name="${elAttributeName}"]`
  const htmlElement = document.querySelector(selector)
  if (!htmlElement) { return }

  if (clientPhoneApps[elAttributeName]?.destroy) { clientPhoneApps[elAttributeName].destroy() }

  clientPhoneApps[elAttributeName] = createVueApp({
    el: selector,
    name: `LegacyClientPhoneApp:${elAttributeName}`,
    render: (h) => h(LegacyClientPhoneApp, {
      props: {
        nameAttr: elAttributeName,
      },
    }),
  })
}
