FormFactory.presets.stock = (params) => {
  let $form = $('#stocks_edit_form')

  params = $.extend({
    model: 'stock',
    window: $('#modal_stocks_edit'),
    form: $form,
    fnItemsPath: Routes.stocks_path,
    fnItemPath: Routes.stock_path,
    checkForChanges: false,
    megamodalParams: {
      btnResize: false,
    },
  }, params)

  return $.extend({},
    FormFactory.core(params),
    FormFactory.modal(params),
    FormFactory.mutable(params),
  )
}
