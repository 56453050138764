/**
 * Вычисление всех денежных штук для энтри, с учетом количества, цены, скидки
 * @param memberAttributes
 * @returns {*}
 */
export const calculateEntryTotals = (memberAttributes) => {
  const amount = parseInt(memberAttributes.amount)
  const price = Utils.toMoney(memberAttributes.price)
  const discountValue = Utils.toMoney(memberAttributes.discount_value)
  const discount = discountValue / 100

  memberAttributes.sum = price * amount
  memberAttributes.final_price = Utils.toMoney(price * (1 - discount))
  memberAttributes.final_sum = amount * memberAttributes.final_price
  memberAttributes.discount_value = discountValue
  memberAttributes.discount_sum = Utils.toMoney(memberAttributes.sum - memberAttributes.final_sum)

  return memberAttributes
}
