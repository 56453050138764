import shared from '../order/shared.js'

FormFactory.dentalOrderEntryCommon = function () {
  return $.extend({}, shared, {
    setPerformersVisibility (visible) {
      const assistant = this.field('assistant_id')

      if (visible) {
        if (assistant) assistant.show()
        this.field('user_id').show()
      } else {
        if (assistant) assistant.hide()
        this.field('user_id').hide()
      }
    },
  })
}
