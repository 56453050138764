FormFactory.presets.appointment_duration = (params) => {
  params = $.extend({
    model: 'appointment_duration',
    form: $('#appointment_duration_form'),
    fnItemPath: Routes.reception_appointment_duration_path,
    fnItemsPath: Routes.reception_appointment_durations_path,
    redirectPath: Routes.new_reception_appointment_duration_path,
    deleted_entity: t('gentitive.appointment_duration'),
  }, params)

  const composition = Object.assign({},
    FormFactory.core(params),
    FormFactory.inputmask(params),
    FormFactory.redirect2sides(params),
    FormFactory.index_table_updator(params),
    FormFactory.delete_button(params),
  )

  return composition
}
