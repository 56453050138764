window.EntryTemplateSelector = (function () {
  const jQM = {}

  const newTemplate = function (data) {
    jQM.modalSaveTemplate.modal('show')
  }

  const init = function (conf) {
    _setJQM()
    _prepareModals()
    _initPlugin()
    _bindEvents()
  }

  const _setJQM = function () {
    //mapping buttons

    jQM.modalSaveTemplate = $('#modal_save_as_template')
    jQM.saveTemplateButton = jQM.modalSaveTemplate.find('.modal-save')
    jQM.modalCloseButton = jQM.modalSaveTemplate.find('.modal-close')

    //mapping categoryModal
    jQM.modalCreateCategory = $('#modal-category-form')

    //inputs
    jQM.dataInput = $('#template_data')
    jQM.htmlInput = $('#template_html')

    //form
    jQM.templateRecForm = jQM.modalSaveTemplate.find('#template-record-form')
    jQM.templateDataInput = jQM.templateRecForm.find('#template_record_template_data')
    jQM.templateHtmlInput = jQM.templateRecForm.find('#template_record_template_html')
    jQM.templateCategoryIdInput = jQM.templateRecForm.find('#template_record_category_id')
  }

  const _prepareModals = function () {
    //prepareTemplateModals
    const createCategoryBtnHtml = `
      <button class="btn btn-primary btn-with-icon" id="add-category" type="button">
        <div class="btn-with-icon_icon fad fa-folder-open"></div>
        <div class="btn-with-icon_text">${t('add_category')}</div>
      </button>
      `
    $(createCategoryBtnHtml).insertAfter(jQM.saveTemplateButton)
    jQM.addCategoryBtn = jQM.modalSaveTemplate.find('#add-category')
    jQM.modalSaveTemplate.find('.modal-body').append('<div id="template-categories-mount"></div>')
    jQM.templateCategoriesMount = jQM.modalSaveTemplate.find('#template-categories-mount')

    //prepare categoryModal
    jQM.modalCreateCategory.find('.modal-body').css('min-height', '0px')
    jQM.modalCreateCategory.find('.modal-save-and-create').remove()

    //forms
  }

  const emitModalClose = () => {
    Services.pubSub.emit('onSaveAsTemplateClose')
  }

  const _initPlugin = function () {
    jQM.templateCategoriesMount.categoriesx({
      url: '/categories/template_categories',
      category_type: 8,
      editable: true,
      groupEdit: false,
      onItemEdit (item) {},
      onItemRemove (item) {},
      onClick (item) {
        console.info(item)
      },
    })

    $(jQM.templateRecForm).remote_form({
      model: 'template_record',
      saveButton: jQM.saveTemplateButton,
      onSuccess (data) {
        $(jQM.modalSaveTemplate).megamodal('hide')
        $('#template_record_title').val('')
        jQM.templateCategoriesMount.categoriesx('forceReload')
        jQM.saveTemplateButton.loadSpin('stop')

        Notificator.success('Шаблон успешно сохранён')
        emitModalClose()
      },
    })
  }

  const _bindEvents = function () {
    jQM.addCategoryBtn.on('click', function (e) {
      jQM.templateCategoriesMount.categoriesx('newCategory')
    })

    jQM.saveTemplateButton.on('click', function (e) {
      jQM.templateDataInput.val(jQM.dataInput.val())
      jQM.templateHtmlInput.val(jQM.htmlInput.val())
      jQM.templateCategoryIdInput.val(
        jQM.templateCategoriesMount.categoriesx('getCategory').id
      )

      //submit
      jQM.templateRecForm.submit()
    })

    jQM.modalCloseButton.on('click', emitModalClose)
  }

  return {
    init,
    newTemplate,
  }
})()

export default EntryTemplateSelector
