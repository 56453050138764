<template>
  <confirmation-modal
    :modal-visibility="modalVisibility"
    :header-message="t('client_matching')"
    :message="modalMessage"
    @yes="onModalEvent(true)"
    @no="onModalEvent(false)"
  />
</template>

<script>
import ConfirmationModal from '@/vue_components/confirmation_modal'
import {
  isIndividualCustomerController,
  MATCHING_CLIENT,
  PUBSUB_CLOSED_EVENTS,
  SHOWED_MODALS,
} from '@/vue_components/client/MatchingClient/consts'
import { getMatchingText, pinText } from '@/vue_components/client/MatchingClient/composables/texts'
import { cloneDeep } from 'lodash'
import { SELECTORS } from '@/vue_components/client/RelationManager/relations_manager'
import formatter from '@/lib/formatters/formatter'

/**
 * Устранение бага когда есть customer_client и legal_representative одновременно (см. isIndividualCustomerController):
 *
 * setGonShowedModals - для порядка открывания модалок
 * resetGonShowedModals - срабатывает при закрытии модалок (onPubSubModalClosed)
 * если getGonShowedModalPrev() falsy, то это значит, что была открыта модалка с заказчиком, иначе с легалом
 */

const resetGonShowedModals = () => {
  gon.specific.showed_modal_prev = null
  gon.specific.showed_modal = null
}
const setGonShowedModals = (value) => {
  gon.specific.showed_modal_prev = gon.specific.showed_modal
  gon.specific.showed_modal = value
}
const getGonShowedModal = () => gon.specific.showed_modal
const getGonShowedModalPrev = () => gon.specific.showed_modal_prev

const isCustomerIndividual = () =>
  isIndividualCustomerController(gon.page.controller) &&
  getGonShowedModal() === SHOWED_MODALS.CUSTOMER_CLIENT &&
  !getGonShowedModalPrev()

export default {
  name: 'MatchingClient',
  components: { ConfirmationModal },

  props: {
    searchClientLegalRepresentative: {
      type: Object,
      required: true,
    },

    selector: {
      type: String,
      required: true,
    },
  },

  data () {
    return {
      modalMessage: '',
      modalVisibility: false,
      currentLR: null,
      successPubSub: MATCHING_CLIENT.SUCCESS,
    }
  },

  created () {
    this.onPubSubModalShowed()
    this.onPubSubModalClosed()
    this.onPubSubSelected()
    this.onPubSubError()
  },

  methods: {
    onPubSubModalShowed () {
      Object.keys(SHOWED_MODALS).forEach((key) => {
        PubSub.on(SHOWED_MODALS[key], () => {
          setGonShowedModals(SHOWED_MODALS[key])
        })
      })
    },

    onPubSubModalClosed () {
      Object.keys(PUBSUB_CLOSED_EVENTS).forEach((key) => {
        PubSub.on(PUBSUB_CLOSED_EVENTS[key], () => {
          resetGonShowedModals()
        })
      })
    },

    onPubSubSelected () {
      PubSub.on(MATCHING_CLIENT.SELECTED, (name, data) => {
        const { result, item } = data
        this.currentLR = result || item
      })
    },

    onPubSubError () {
      PubSub.on(MATCHING_CLIENT.ERROR, (name, { result }) => {
        this.successPubSub = MATCHING_CLIENT.SUCCESS
        this.pubSubError(name, result)
      })

      PubSub.on(MATCHING_CLIENT.CUSTOMER_ERROR, (name, { result }) => {
        this.successPubSub = MATCHING_CLIENT.CUSTOMER_SUCCESS
        this.pubSubError(name, result)
      })
    },

    pubSubError (name, result) {
      if (!this.currentLR) { return }
      if (!result.responseText) { return }

      const successCondition =
          (this.selector === SELECTORS.APPOINTMENT && getGonShowedModal() === SHOWED_MODALS.APPOINTMENT) ||
          (this.selector === SELECTORS.WAITING_LIST && getGonShowedModal() === SHOWED_MODALS.WAITING_LIST) ||
          (this.selector === SELECTORS.CLIENT_FORM && getGonShowedModal() === SHOWED_MODALS.CLIENT) ||
          (this.selector === SELECTORS.CLIENT_FORM)

      if (!successCondition) { return }

      this.modalMessage = `${getMatchingText(result.responseText)}${pinText}`
      this.modalOpen()
    },

    onModalEvent (result) {
      if (result) {
        PubSub.emit(
          this.successPubSub,
          { queueLength: 0, result: cloneDeep(this.currentLR) }
        )

        const legalFullName = formatter.formatValue(this.currentLR, ['client:fullName'])
        const legalPhone = this.currentLR.masked_phone ||
          '+' + Services.phoneMask.setMask(this.currentLR.phone)

        const legalBirthday = Utils.getFormattedDate(this.currentLR.birthdate)

        const shortInfo = [
          legalFullName,
          legalBirthday,
          legalPhone,
        ].filter((el) => el).join(', ')

        if (isCustomerIndividual()) {
          Services.pubSub.emit('ORDER.PAYER_SELECTOR.SET_PAYER', this.currentLR)
        } else {
          this.searchClientLegalRepresentative.append(
            new Option(shortInfo, this.currentLR.id, true, true)
          )
          this.searchClientLegalRepresentative.trigger('change')
        }

        this.currentLR = null
      }

      resetGonShowedModals()
      this.modalClose()
    },

    modalClose () { this.modalVisibility = false },
    modalOpen () { this.modalVisibility = true },
  },
}
</script>
