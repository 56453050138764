import DurationSelector from './duration_selector.vue'
import Vue from 'vue/dist/vue.esm'

export default (params) => {
  return new Vue({
    el: params.mountElId,
    components: { DurationSelector },
    data: {
      durations: [...params.durations],
      current: params.minExistingDuration,
      maxValue: params.maxExistingDuration,
      minValue: params.minExistingDuration,
      hidden: false,
    },
    watch: {
      current (newVal) {
        params.durationHiddenInput.val(newVal)
        params.updateReservation()
      },
    },
    methods: {
      adjustDuration (appointment) {
        const startTime = appointment.time
        appointment._worktime = Array.isArray(appointment._worktime) ? appointment._worktime : []
        const conformTimeRange = appointment._worktime
          .find((timeRange) => timeRange[0] <= startTime && timeRange[1] >= startTime)
        const maxDuration =
          conformTimeRange ? Utils.timeRangeMinutes(startTime, conformTimeRange[1]) : null

        appointment.duration = appointment.duration || appointment.user.appointment_duration
        maxDuration && (appointment.duration = appointment.duration > maxDuration ? maxDuration : appointment.duration)

        if (appointment._worktime.length === 0 || !maxDuration) {
          this.hidden = true
        } else {
          this.hidden = false
        }

        if (maxDuration) { this.setMaxValue(maxDuration) }
        const dur = appointment.duration || this.minValue
        this.set(dur)
        params.durationHiddenInput.val(dur)
      },
      setMaxValue (maxDuration) {
        this.maxValue = maxDuration

        this.durations = params.durations.filter((d) => d <= maxDuration)
      },
      set (value) {
        if (this.durations.includes(value)) this.current = value
        else {
          const maxItem = Math.max(...this.durations)

          if (value > this.maxValue) this.current = maxItem
          else this.updateDurations(value)
        }
      },
      updateCurrent (value) {
        this.current = value
      },
      updateDurations (newDuration) {
        this.durations.push(newDuration)
        this.current = newDuration
      },
    },
    template: `<div id="duration-select-container-vue">
                <duration-selector
                  v-if="!hidden"
                  :durations="durations" 
                  :current="current"
                  :maxValue="maxValue"
                  :minValue="minValue"
                  @updateCurrent="updateCurrent"
                  @newDuration="updateDurations" />
                <span class="prompt-notice" v-else>{{ t('appointment_not_available') }}</span>
              </div>`,
  })
}
