FormFactory.presets.medical_policy = (params) => {
  params = $.extend({
    model: 'medical_policy',
    form: $('#medical_policy_form'),
    fnItemsPath: Routes.medical_policies_path,
    fnItemPath: Routes.medical_policy_path,
    window: $('#modal_medical_policy_form'),
    defaultCompanyTypeId: 2,
  }, params)

  const { form } = params
  const $companySubmit = form.find('#company_submit_btn')
  const $creatingInsurerButton = form.find('#create_medical_policy_company')
  const $insurerModal = $('#medical_policy_company_modal')
  const $insurerForm = $insurerModal.find('#company_form')
  const $insurerSelector = form.find('#medical_policy_company_id')

  FormFactory.build('companyFormModalWindow', {
    modalWindow: $insurerModal,
    form: $insurerForm,
    components: {
      modal: { model: 'insurer' },
      remoteForm: {
        onSuccess () {},
      },
    },
  })

  $insurerForm.on('ajax:success', (_, insurer) => {
    $insurerSelector.append(new Option(insurer.title, insurer.id, true, true))
    $insurerSelector.trigger('change')
  })

  $creatingInsurerButton.click((event) => {
    $insurerModal.modal('show')
  })

  const composition = Object.assign({},
    FormFactory.core(params),
    FormFactory.companyField(params),
  )

  if (params.mode === 'modal') {
    Object.assign(composition,
      FormFactory.modal(params),
    )
    form.find(':submit').hide()
  } else {
    Object.assign(composition,
      FormFactory.redirect(params),
    )
  }

  $companySubmit.hide()

  $('#medical_policy_company_id').select2({ width: '100%', 'language': gon.localization.locale })

  $('.select2-hidden-accessible').on('select2:open', function () {
    $('.select2-search__field').attr('placeholder', t('search'))
  })

  return composition
}
