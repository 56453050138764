FormFactory.presets.discount_reason = (params) => {
  params = $.extend({
    model: 'discount_reason',
    form: $('#discount_reason_form'),
    fnItemPath: Routes.discount_reason_path,
    fnItemsPath: Routes.discount_reasons_path,
    redirectPath: Routes.new_discount_reason_path,
    deleted_entity: t('gentitive.discount_reason'),
  }, params)

  const composition = Object.assign({},
    FormFactory.core(params),
    FormFactory.inputmask(params),
    FormFactory.redirect2sides(params),
    FormFactory.index_table_updator(params),
    FormFactory.delete_button(params)
  )

  return composition
}
