import { clientsEndpoint } from '@/api_entities/clients/clients_endpoint'
import Vue from 'vue/dist/vue.esm'
import { cloneDeep } from 'lodash'
import LegalRepRelationsModalForClientForm
  from '@/vue_components/client/RelationManager/LegalRepRelationsModalForClientForm'

export const SELECTORS = {
  CLIENT_FORM: 'clients_form',
  APPOINTMENT: 'modal_form_appointment_edit',
  WAITING_LIST: 'form_waiting_list_modal',
}

export const extractConfig = (selectorName) => {
  switch (selectorName) {
    case SELECTORS.CLIENT_FORM: return {
      setEdit: 'page.form.client.setEdit',
      askSetEdit: '',
    }
    case SELECTORS.APPOINTMENT: return {
      setEdit: 'page.form.appointment.setEdit',
      askSetEdit: 'page.form.appointmentClient.mutable.askSetEdit',
    }
    case SELECTORS.WAITING_LIST: return {
      setEdit: 'page.form.waiting_list.setEdit',
      askSetEdit: 'page.form.WaitingListClient.mutable.askSetEdit',
    }
    default: return {}
  }
}

export const extractMainSelector = (jqSelector) => {
  if (!(jqSelector && jqSelector.selector)) { return null }

  const splitSelectorName = jqSelector.selector.split(' ')
  if (!splitSelectorName.length) { return null }

  return splitSelectorName[0].substring(1)
}

export const relationsManagerVue = (searchClientLegalRepresentative) => {
  if (!searchClientLegalRepresentative) {
    throw new Error('Cant find searchClientLegalRepresentative')
  }

  const selector = extractMainSelector(searchClientLegalRepresentative)
  if (!Object.values(SELECTORS).includes(selector)) { return }

  const {
    setEdit,
    askSetEdit,
  } = extractConfig(selector)

  return new Vue({
    el: '#relations_manager',
    name: 'RelationsManager',

    components: {
      LegalRepRelationsModalForClientForm,
    },

    data () {
      return {
        clientId: parseInt(gon.page.params.id || gon.specific.client_id),
        legalReps: [],
        relations: [],
        relationsLoading: false,
        visibility: false,
        searchClientLegalRepresentative,
      }
    },

    watch: {
      relationsLoading (to) {
        $('#manage_relations').spin(to)
      },
    },

    created () {
      if (gon.specific[selector]) { return }
      gon.specific[selector] = true

      PubSub.on(setEdit, (target, { item }) => {
        this.setLegalReps()
        this.getRelations(item && item.client && item.client.id)
      })

      PubSub.on(askSetEdit, (target, { item }) => {
        this.setLegalReps()
        this.getRelations(item.id)
      })
    },

    mounted () {
      this.setLegalReps()

      searchClientLegalRepresentative.on('change', this.onSearchClientLegalRepresentativeChange)

      this.getRelations(this.clientId)
    },

    beforeDestroy () {
      searchClientLegalRepresentative.off('change', this.onSearchClientLegalRepresentativeChange)
    },

    methods: {
      open () {
        this.visibility = true
      },

      setLegalReps () {
        this.legalReps = this.getLegalReps()
      },

      onSearchClientLegalRepresentativeChange () {
        this.setLegalReps()
        const legalRepsIds = this.legalReps.map((item) => item.id)

        // удаление убранных из select2 отношений
        this.relations = this.relations.filter((item) => legalRepsIds.includes(item.legal_representative_id))

        // добавление новых из select2 отношений
        const relationsIds = this.relations.map((item) => item.legal_representative_id)
        const newRelationsIds = legalRepsIds.filter((id) => !relationsIds.includes(id))
        const newRelations = newRelationsIds.map((id) => ({ legal_representative_id: id, relation_type_id: null }))
        this.relations = [...cloneDeep(this.relations), ...cloneDeep(newRelations)]

        clientsEndpoint.setRelations(this.relations, searchClientLegalRepresentative).then()
      },

      getLegalReps () {
        return searchClientLegalRepresentative
          .select2('data')
          .map(({ id, text }) => {
            return {
              id: parseInt(id),
              name: text.split(', ')[0],
            }
          })
      },

      getRelations (clientId) {
        if (!clientId) { return }

        this.relationsLoading = true
        clientsEndpoint.getRelations(clientId)
          .then((data) => {
            this.relations = data
            clientsEndpoint.setRelations(data, searchClientLegalRepresentative).then() // инжектится массив в форму
          })
          .catch(Utils.reportError('client.js:getRelationsByClientId()'))
          .finally(() => { this.relationsLoading = false })
      },
    },

    template: `
      <LegalRepRelationsModalForClientForm
          v-if="visibility"
          :client-id="clientId"
          :legal-reps="legalReps"
          :visibility.sync="visibility"
          :relations="relations"
          :relationsLoading="relationsLoading"
          :searchClientLegalRepresentative="searchClientLegalRepresentative"
      />
    `,
  })
}
