FormFactory.presets.role = (params) => {
  params = $.extend({
    model: 'role',
    form: $('#role_form'),
    fnItemPath: Routes.role_path,
    fnItemsPath: Routes.roles_path,
    redirectPath: Routes.new_role_path,
    deleted_entity: t('gentitive.role'),
  }, params)

  const {form} = params
  const composition = Object.assign({},
    FormFactory.core(params),
    FormFactory.inputmask(params),
    FormFactory.redirect2sides(params),
    FormFactory.index_table_updator(params),
    FormFactory.delete_button(params),
  )

  return composition
}
