import EpicPubSub from '../../services/epic_pub_sub.js'

FormFactory.presets.user = (params) => {
  $('#user_specialty_ids').select2()
  $('#user_cabinet_id').select2()
  $('#past_user_random_data').pastFakeUserData()

  const $userForm = $('#user_form')
  const $submitButtons = $userForm.find('button[type="submit"]')
  const $userSex = $('#user_sex')
  const $hasAppointment = $('#user_has_appointment')
  const workplaceField = $('#user_workplace_id')
  const $appointmentDurationBlock = $('#appointment_duration_block')
  const cashierBlock = $('.cashier_block')

  function redrawDynamicFields () {
    if ($hasAppointment.val() === 'true') {
      $appointmentDurationBlock.show()
    } else {
      $appointmentDurationBlock.hide()
    }

    if (workplaceField.val()) {
      cashierBlock.show()
    } else {
      cashierBlock.hide()
    }
  }

  setTimeout(() => {
    $userSex.change(function () {
      const sex = this.value
      if (sex === 'true' || sex === 'false') EpicPubSub.emitAsync('USER_FORM.SEX', JSON.parse(sex))
    })
  }, 0)

  redrawDynamicFields()
  $hasAppointment.on('change', redrawDynamicFields)
  workplaceField.on('change', redrawDynamicFields)

  FormFactory.userPassword()

  if (new URLSearchParams(window.location.search).get('userHasAppointment') === 'true') {
    setTimeout(() => $hasAppointment.val('true').trigger('change'))
  }

  PubSub.subscribe('page.form.user.submitSuccess', () => {
    $submitButtons.loadSpin('stop')

    if (pageInfo.action === 'new') {
      PubSub.emit('page.form.user.askClear')
    }
  })

  params = $.extend({
    model: 'user',
    form: $userForm,
    fnItemPath: Routes.user_path,
    fnItemsPath: Routes.users_path,
    redirectPath: Routes.new_user_path,
    deleted_entity: t('gentitive.user'),
  }, params)

  // const { form } = params
  const composition = Object.assign({},
    FormFactory.core(params),
    FormFactory.inputmask(params),
    FormFactory.redirect2sides(params),
    FormFactory.index_table_updator(params),
    FormFactory.delete_button(params)
  )

  return composition
}
