FormFactory.presets.entry = function (params) {
  const $form = $('#entry_form')
  const msgPrefix = 'page.form.entry.'

  const $fields = {
    client_id: $('#entry_client_id'),
    clinic_id: $('#entry_clinic_id'),
    price: $('.f-entry-price'),
    final_price: $('.f-entry-final_price'),
    sum: $('.f-entry-sum'),
    final_sum: $('.f-entry-final_sum'),
    discount_sum: $('.f-entry-discount_sum'),
    amount: $('#entry_amount'),
    kind: $('#entry_kind'),
    performer: $('#entry_user_id'),
    assistant: $('#entry_assistant_id'),
  }

  const COMPLEX = 6
  const isComplex = parseInt($fields.kind.val()) === COMPLEX

  const entry = $.extend({}, gon.specific.entry)
  entry.discount_value = parseFloat(entry.discount_value)

  params = $.extend({
    model: 'entry',
    form: $form,
    fnItemsPath: Routes.entries_path,
    fnItemPath: Routes.entry_path,
    redirectBackFrom: 'entry_edit',
    onRedirectMatchFailPath: Routes.medical_record_entry_path(gon.specific.medical_record_id, gon.specific.entry.id),
    lockedFromBillingChanges: gon.specific.locked,
    dTableParams: {},
    entryTypeSelector: [
      {
        selector: '#members_list_table thead .entry_type_selector',
        editable: false,
        filterItems (item) { return (item.kind !== COMPLEX) },
        forCurrentClinic: true,
        forCurrentUser: true,
      },
      {
        selector: '#consumables_list_table thead .entry_type_selector',
        editable: false,
        onlyGoods: true,
        forCurrentClinic: true,
        forCurrentUser: true,
      },
      {
        selector: '.f-member-consumables-selector-row .entry_type_selector',
        editable: false,
        onlyGoods: true,
        forCurrentClinic: true,
        forCurrentUser: true,
      },
    ],
    entryTemplatable: {
      button: $('#fill_from_template'),
      saveAsTemplateButton: $('#save_as_template'),
      updateFormButton: $('#update_form'),
    },
    entryLists: {
      initialAmount: parseInt($fields.amount.val()),
    },
    MKBSelector: {
      selector: $('#mkb_selector'),
    },
    nestedDiagnoses: {
      container: $('#nested-diagnoses'),
    },
    notificator: {
      messages: {
        state: t('select_status'),
      },
    },
  }, params)

  //
  // core
  //

  function updateEntryListAttributes () {
    const attributes = {
      clinic_id: parseInt($fields.clinic_id.val()),
      client_id: parseInt($fields.client_id.val()),
      amount: parseInt($fields.amount.val()),
    }
    PubSub.emit(msgPrefix + 'entryLists.askSetAttributes', {
      attributes,
    })
  }

  //
  // components
  //

  PubSub.on(msgPrefix + 'entryTypeSelector.selected', function (msg, data) {
    data = $.extend({}, data, { entry })
    PubSub.publish(msgPrefix + 'entryLists.askAddElement', data)
  })

  PubSub.on(msgPrefix + 'MKBSelector.selected', function (msg, data) {
    PubSub.emit(msgPrefix + 'nestedDiagnoses.addFromDisease', {
      item: data.item,
    })
    PubSub.emit(msgPrefix + 'updateComponents')
  })

  PubSub.on(msgPrefix + 'entryLists.listChanged', function (msg, data) {
    if (!isComplex) {
      addOwnPriceToSums(data)
    } else {
      setEntryPrices(data)
    }

    setEntrySums(data)
  })

  function addOwnPriceToSums (sums) {
    const totalAmount = +$fields.amount.val()
    const price = +$fields.price.val()
    const finalPrice = +$fields.final_price.val()

    sums.sum += price * totalAmount
    sums.final_sum += finalPrice * totalAmount
    sums.discount_sum = sums.sum - sums.final_sum
  }

  function setEntryPrices (prices) {
    const totalAmount = +$fields.amount.val()
    $fields.price.val(prices.price / totalAmount)
    $fields.final_price.val(prices.final_price / totalAmount)
  }

  function setEntrySums (sums) {
    $fields.sum.val(sums.sum)
    $fields.final_sum.val(sums.final_sum)
    $fields.discount_sum.val(sums.discount_sum)
  }

  PubSub.on(msgPrefix + 'setEdit', updateEntryListAttributes)
  PubSub.on(msgPrefix + 'setNew', updateEntryListAttributes)

  //
  // events
  //

  $('#entry-diagnoses').on('click', '.f-nested-destroy', function (e) {
    e.preventDefault()
    const $input = $(this).siblings(':input')
    $input.val('1')
    $input.closest('.entry-diagnosis').hide()
  })

  $fields.amount.on('change keyup mouseup', updateEntryListAttributes)

  //
  // post init
  //

  if (params.lockedFromBillingChanges) {
    $fields.amount.attr('disabled', 'disabled')
  }

  return $.extend({},
    FormFactory.core(params),
    FormFactory.entryTemplatable(params),
    FormFactory.redirect(params),
    FormFactory.entryTypeSelector(params),
    FormFactory.entryMember(params),
    FormFactory.entryConsumable(params),
    FormFactory.entryLists(params),
    FormFactory.textAutosize(params),
    FormFactory.MKBSelector(params),
    FormFactory.notificator(params),
    FormFactory.teethMap(params),
    FormFactory.nestedDiagnoses(params),
    FormFactory.attachments(params)
  )
}
