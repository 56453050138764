/**
* @param params
* @returns {{}}
*/
FormFactory.insuranceCompanyField = function (params) {
  var insuranceCompanySelect = params.insuranceCompanyField.target
  var insuranceCompanyForm = $('#insurance_company_form')
  var formInsuranceCompanyModal = $('#form_insurance_company_modal')
  var createInsuranceCompanyBtn = $('#create_insurance_company_btn')
  var formActive = false

  if (!insuranceCompanyForm.length) {
    throw new Error('insurance company form not found on page')
  }

  insuranceCompanySelect.select2({width: '100%', 'language': gon.localization.locale})

  createInsuranceCompanyBtn.on('click', function () {
    insuranceCompanyForm.remote_form('clear')
    formInsuranceCompanyModal.modal('show')
    formActive = true
  })

  formInsuranceCompanyModal.find('.modal-save').click(function () {
    if (formActive) {
      insuranceCompanyForm.submit()
    }
  })

  formInsuranceCompanyModal.find('.modal-close').click(function () {
    formActive = false
  })

  formInsuranceCompanyModal.find('.modal-header-close').click(function () {
    formActive = false
  })

  insuranceCompanyForm.remote_form({
    model: 'insurance_company',
    onSuccess (elem) {
      formInsuranceCompanyModal.modal('hide')
      formActive = false
      var newOption = new Option(elem.title, elem.id, true, true)
      insuranceCompanySelect.append(newOption).trigger('change')
    },
  })

  return {
  }
}
